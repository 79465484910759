import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUserRate} from "../../../redux/reducers/chatInfo";
import styles from "../Chat.module.css";
import selectedStar from "../../../assets/images/chat/ratingStarSelected.svg";
import unselectedStar from "../../../assets/images/chat/ratingStarUnselected.svg";

const ClientRating = () => {
    const settledRating = useSelector(state => state.chatInfo.userInfo.rate)
    const [rating, setRating] = useState(settledRating)
    const dispatch = useDispatch()

    useEffect(() => {
        setRating(settledRating)
    }, [settledRating])

    const onStarClick = (item) => {
        //if (settledRating !== 0) return
        setRating(item)
        dispatch(setUserRate(item))
    }

    return <div className={styles.stars}>
        {
            [1, 2, 3, 4, 5].map((item, index) => {
                return rating >= item ? <img key={index} onClick={() => onStarClick(item)} src={selectedStar}/> :
                    <img key={index} onClick={() => onStarClick(item)} src={unselectedStar}/>
            })
        }
    </div>
}

export default ClientRating
