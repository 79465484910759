import {useDispatch, useSelector} from "react-redux";
import styles from "../Chat.module.css";
import React, {useEffect, useRef} from "react";
import Message from "./Message";
import {getPreviousMessages, selectMessages} from "../../../redux/reducers/chatInfo";
import {Button} from "@mui/material";
import {useParams} from "react-router-dom";
import cn from "classnames";

const ChatInner = () => {
    const {id} = useParams()
    const messages = useSelector(selectMessages)
    const canPaginate = useSelector(state=>state.chatInfo.loadMoreAvailable)
    const dispatch = useDispatch()
    const updaterRef = useRef(null)
    const scrollRef = useRef(null)

    function loadMore () {
        dispatch(getPreviousMessages({id}))
    }

    useEffect(()=>{
        if (!scrollRef.current || !updaterRef.current) return

        scrollRef.current.addEventListener('scroll', (e)=>{
            updaterRef.current.textContent = e.target.scrollTop
        })

    }, [scrollRef.current, updaterRef.current])

    return <div ref={scrollRef} className={styles.chatInnerWrapper}>
        {messages.map((item, index) => <Message message={item} key={item.id || `idx_${index}`}/>)}
        {canPaginate && <Button onClick={loadMore}>Загрузить еще</Button>}
        <span ref={updaterRef} className={'hidden'}/>
    </div>
}

export default ChatInner
