import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from './redux/store/index'
import LeftBar from "./components/LeftBar/LeftBar";
import CallAlert from "./components/CallAlert/CallAlert"
import MainRouter from "./router";
import { BrowserRouter } from "react-router-dom";
import Auth from "./components/Auth/Auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ModalSpectator from "./components/common/ModalSpectator";
import { Button } from "@mui/material";
import { AcUnit, Fullscreen, FullscreenExit } from "@mui/icons-material";
import cn from "classnames";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { loadCatalogue } from "./redux/reducers/catalogues";
import { useMatchMedia } from "./hooks/hooks";

const queryClient = new QueryClient()

const App = () => {

    return (
        <QueryClientProvider client={ queryClient }>
            <BrowserRouter>
                <Provider store={ store }>
                    <ModalSpectator />
                    <Content />
                </Provider>
            </BrowserRouter>
        </QueryClientProvider>
    );
}

const Content = () => {
    let auth = useSelector(state => state.user.user.token)
    const dispatch = useDispatch()
    const { isDesktop } = useMatchMedia()

    useEffect(() => {
        dispatch(loadCatalogue({ shopId: 2 }))
    }, [])

    if (!auth) return <Auth />

    return <>
        <ToastContainer />
        <div className={ `h-screen flex justify-center bg-black` }>
            <div style={ { flexDirection: isDesktop ? 'row' : 'column' } } className={ `flex w-full h-full rounded-l overflow-hidden` }>
                <LeftBar />
                <MainRouter />
                <CallAlert />
            </div>
        </div>
    </>
}


export default App
