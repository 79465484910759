import React from 'react'
import SText from "../../utils/SText";
import styles from '../styles.module.css'
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import RedeemIcon from "@mui/icons-material/Redeem";
import { Add, PlusOne, Remove } from "@mui/icons-material";
import Colors from "../../utils/Colors";
import { useSelector } from "react-redux";
import { ECO_BASE_DOMAIN } from "../../../api";
import { selectProduct, selectProducts } from "../../../redux/reducers/catalogues";


export const ProductId = ({ id, amount = 0, price, onAmountChange, onPriceChange }) => {
    const product = useSelector(selectProduct(id))


    return <Product name={ product.title }
        image={ ECO_BASE_DOMAIN + product.thumb }
        price={ price ?? product.price }
        id={ id }
        amount={ amount }
        onPriceChange={ onPriceChange }
        onAmountChange={ onAmountChange }
    />
}
const Product = ({ name, id, image, amount, onAmountChange, disabled = false, price, onPriceChange }) => {

    const createAmountChanger = (delta) => () => {
        onAmountChange(id, amount + delta)
    }

    const motto = Math.floor(price * amount * 0.05)

    return <div className={ 'flex flex-row bg-gray-200 gap-3 h-12 items-center p-1 rounded-md max-w-4xl' }>
        <img className={ 'h-10 w-10 rounded ml-1' } src={ image } />
        <SText size={ 14 } lineHeight={ 16 } weight={ 500 } className={ styles.productName }>
            { name }
        </SText>
        <TextField size={ 'small' }
            className={ 'bg-white rounded w-28' }
            type={ 'number' }
            disabled={ disabled }
            value={ price }
            onChange={ e => onPriceChange(id, e.target.value) }
            InputProps={ { endAdornment: <InputAdornment position={ 'end' }>{ '₽' }</InputAdornment> } }
        />
        <div className={ 'flex flex-row items-center bg-blue-500 rounded-full' }>
            { !disabled && <IconButton sx={ { color: '#fff' } } size={ 'small' } onClick={ createAmountChanger(-1) }>
                <Remove />
            </IconButton> }
            <SText weight={ 500 } size={ 13 } div className={ 'p-2' } color={ '#fff' }>{ amount + ' шт' }</SText>
            { !disabled && <IconButton sx={ { color: '#fff' } } size={ 'small' } onClick={ createAmountChanger(+1) }>
                <Add />
            </IconButton> }
        </div>
    </div>
}

export default Product
