import axios from "axios";

export const BASE_URL = 'https://apicrm.ecomarket.ru/api/v1/'
export const ECO_BASE_URL = 'https://api.ecomarket.ru/api.php'
export const ECO_HARD_URL = 'https://api.ecomarket.ru/hard_api.php'
export const ECO_BASE_DOMAIN = 'https://api.ecomarket.ru'

export function createHeaders() {
    const token = localStorage.getItem('token')// '3387cbd28d334267a7cab85efe7b6008d4960def'
   // const token = '3387cbd28d334267a7cab85efe7b6008d4960def'

    if (!token) return {}
    return {
            Authorization: `Bearer ${token}`
    }
}

export const instance = axios.create({
    baseURL: BASE_URL,
    headers: createHeaders(),
})

export async function sendEcoRequest (action, payload = {}, hard = false) {
    return await axios({
        baseURL: hard ? ECO_HARD_URL :ECO_BASE_URL,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Accept': 'application/json'
        },
        data: {
            action,
            ...payload
        },
        credentials: 'include',
    }).then(r=>r.data.data)
}

export async function getDeliverySlots({lat, lon}) {
    return await sendEcoRequest('getDeliveryTypes_v2', {lat, lng: lon})
}
