import {west} from "../Chat/components/MessageTypes/ComplaintReport";

export function numberWithSpaces(x) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export function onImgError ({ currentTarget }) {
        currentTarget.onerror = null;
        currentTarget.src=west;
}

