import cn from "classnames";
import styles from "../message.module.css";
import greenTriangle from "../../../../assets/images/chat/greenMessageTriangle.svg";
import whiteTriangle from "../../../../assets/images/chat/whiteMessageTriangle.svg";
import SText from "../../../utils/SText";
import ImagePreview from "../../../ImagePreview";
import React from "react";
import {getMessageSource, MessageMeta} from "../Message";
import sms from '../../../../assets/images/attachment/messageBuble.svg'
import {Button} from "@mui/material";
import {PlayArrow} from "@mui/icons-material";

export function getExtraCN (from, type) {
    return (from == 1) ? {
        layout: styles.containerSent,
        bubble: styles.bubbleSent,
    } : {
        layout: styles.containerReceived,
        bubble: styles.bubbleReceived
    }
}

const Video = ({src}) => {

    return <video className={'max-h-96 w-auto rounded'} src={src} controls/>
}

const TextMessage = ({message, meta}) => {

    const extraCN = getExtraCN(message.from || message.direction, message.messageType)

    return <div className={cn(styles.textMessage, extraCN.bubble)}>
        <div className={cn(styles.payloadMessageHeader, 'mt-0 mb-1')}>
            <img src={sms}/>
            <SText size={14} weight={500}>{getMessageSource(message.type)}</SText>
        </div>
        <SText size={14} weight={500} color={"#000"}>{message.text}</SText>
        {message.messagesImages?.length ? <div className={styles.messageAttachementsRow}>
            {message.messagesImages.map((img, index)=>{
                return message.attType === 'video' ? 
                    <Video key={img.id ?? index} src={img}/>
                    : message.attType == 'audio' ?
                    (
                        <audio key={img.id ?? index} preload={'auto'} autobuffer={"true"} controls className={'bg-transparent'}>
                            <source src={img}/>
                        </audio>
                    )
                    : message.attType == 'document' ? <a key={img.id ?? index} href={img} target="_blank">{message.attType}</a>
                    : message.attType == 'call_log' ? <span key={img.id ?? index}>Пропущенный вызов</span>
                    : message.attType !== 'image' && message.attType !== '' ? <a href={img} target="_blank">{message.attType}</a>
                    : <ImagePreview src={img.image ?? img} key={img.id ?? index} className={styles.messageAttachement}/>
            })}
        </div> : null}
        <MessageMeta date={message.date} dispatcherInfo={message.dispatcherInfo}/>
    </div>
}


export default TextMessage
