import {configureStore} from "@reduxjs/toolkit";
import userReducer from '../reducers/user'
import ticketsReducer from '../reducers/tickets'
import categoriesReducer from '../reducers/categories'
import chatInfoReducer from '../reducers/chatInfo'
import complaintSlice from '../reducers/complaint'
import catalogues from "../reducers/catalogues";

const store = configureStore({
    reducer: {
        user: userReducer,
        tickets: ticketsReducer,
        categories: categoriesReducer,
        chatInfo: chatInfoReducer,
        complaint: complaintSlice,
        catalogues: catalogues
    }
})

window.state = store.getState

export default store
