import cn from "classnames";
import {Controller} from "react-hook-form";
import {InputAdornment, TextField} from "@mui/material";
import Select from "react-select";
import React from "react";

Select.defaultProps= {
    menuPortalTarget: document.body
}

export const ConnectedInput = ({name = '', rules={}, adornment=false, control, className = '', size = 'small', ...props}) => {

    return <div className={cn(className, 'flex items-center bg-white rounded')}>
        <Controller name={name}
                    control={control}
                    rules={rules}
                    render={({field, fieldState}) => (
                        <TextField size={size}
                                   className={'flex-1'}
                                   variant={'outlined'}
                                   error={fieldState.error}
                                   InputProps={{
                                       endAdornment: adornment ?
                                           <InputAdornment position={'end'}>{adornment}</InputAdornment> :
                                           undefined
                                   }}
                                   {...field}
                                   {...props}
                        >
                        </TextField>
                    )}
        />
    </div>
}

export const ConnectedSelect = ({name, options=[], control, disabled=false}) => {
    return (
        <Controller
            render={({field}) => {
                return <Select
                    isDisabled={disabled}
                    options={options}
                    value={options.find(c => c.value === field.value)}
                    onChange={val => field.onChange(val.value)}
                    className={'flex-1'}/>
            }
            }
            control={control}
            name={name}
        />
    )
}
