import React, {useEffect, useState} from "react";
import styles from './Chat.module.css'
import {useDispatch, useSelector} from "react-redux";
import preloader from '../../assets/images/chat/preloader.gif'
import ChatHeader from "./components/Header";
import ChatInner from "./components/ChatInner";
import ChatFooter from "./components/Footer";
import {useParams} from "react-router-dom";
import {getLatestMessages, getMessages, selectMessages} from "../../redux/reducers/chatInfo";
import useWebSocket from "react-use-websocket";
import moment from "moment";
import {CircularProgress} from "@mui/material";
import {getTickets} from "../../redux/reducers/tickets";
import {useMatchMedia} from "../../hooks/hooks";

//const socketUrl = 'wss://gisp.gov.ru:8443/knowbase/app/app/QKk3fukP8YrCYy9H7mABMBYe'
const socketUrl = 'wss://demo.piesocket.com/v3/channel_123?api_key=VCXCEuvhGcBDP7XhiJJUDvR1e1D3eiVjgZ9VRiaV&notify_self'

const readyStates = [
    'UNINSTANTIATED',
    'CONNECTING',
    'OPEN',
    'CLOSING',
    'CLOSED',
]

const Chat = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const chatPending = useSelector(state => state.chatInfo.pending)
    const messages = useSelector(selectMessages)
    const {isMobile} = useMatchMedia()
    useEffect(()=>{
        if (id)
            dispatch(getMessages({id, initial: true}))
    }, [id])

    useEffect(()=>{
        let timer
        function ping () {
            timer = setTimeout(()=> {
                let _id = id
                dispatch(getLatestMessages({id: _id}))
                if (_id === id)
                    ping()
            }, 5000)
        }
        ping()

        return () => {
            clearTimeout(timer)
        }

    }, [id])

   if (!messages?.length) return <div className={'bg-gray-50 flex w-full h-full items-center justify-center'}>
        <CircularProgress size={30} thickness={5}/>
    </div>

    return <div className={isMobile ? styles.chatWrapperMobile : styles.chatWrapper}>
        {chatPending && <div className={styles.preloader}>
            <img src={preloader}/>
        </div>}
        <ChatHeader/>
        <ChatInner/>
        <ChatFooter/>
    </div>
}




export default Chat
