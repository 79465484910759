import bell from "../../../../assets/images/attachment/bell.svg";
import React from "react";
import {PayloadMessageContainer} from "../Message";


const TicketClose = ({message}) => {

    const fullName = [message.user?.name, message.user?.surname].join(' ')
    return <PayloadMessageContainer
        message={message}
        meta={{icon: bell, title: `${fullName} закрыл обращение`}}
    >

    </PayloadMessageContainer>
}

export default TicketClose
