import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ATCcall, sendMessage} from "../../../redux/reducers/chatInfo";
import styles from "../Chat.module.css";
import pinDown from "../../../assets/images/chat/pinDown.svg";
import emoji from "../../../assets/images/chat/emoji.svg";
import Picker from "emoji-picker-react";
import cn from "classnames";
import waWhite from "../../../assets/images/chat/waWhite.svg";
import waBlack from "../../../assets/images/chat/waBlack.svg";
import SText from "../../utils/SText";
import Colors from "../../utils/Colors";
import plus from "../../../assets/images/chat/plus.svg";
import ecoPointsWhite from "../../../assets/images/chat/ecoPointsWhite.svg";
import triangle from "../../../assets/images/chat/triangle.svg";
import phone from "../../../assets/images/chat/phone.fill.svg";
import EcoPointsModal from "./BonusesModal";
import Templates from "./Templates";
import Resizer from "react-image-file-resizer";
import ImagePreview from "../../ImagePreview";
import message from "./Message";
import {Button, TextareaAutosize, TextField} from "@mui/material";
import {Send} from "@mui/icons-material";
import {getVisibilityConfig, useVisibility} from "./MessageTypes/utils";
import {useMatchMedia} from "../../../hooks/hooks";


export const AttachedImage = ({image, onRemove}) => {
    return <div className={styles.imageThumb}>
        <div className={styles.thumbRemove} onClick={onRemove}>
            <SText weight={900} size={14} lineHeight={14} color={'#fff'}>&times;</SText>
        </div>
        <ImagePreview src={image} className={'flex-1 rounded-1'}/>
    </div>
}

export const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920,
            1920,
            "JPEG",
            95,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const ChatFooter = () => {
    const {inputs, isCourier, isSeason} = useVisibility()

    const [sendIn, setSendIn] = useState('5')
    const [modalVisible, setModalVisible] = useState(false)
    const [templatesVisible, setTemplatesVisible] = useState(false)

    useEffect(()=>{
        if (isCourier)
            setSendIn('13')
        else if (isSeason)
            setSendIn('2')
        else
            setSendIn('5')
    }, [isCourier, isSeason])

    const {userInfo, ticketId} = useSelector(state => state.chatInfo)

    const [value, setValue] = useState('')
    const dispatch = useDispatch()
    const [showPicker, setShowPicker] = useState(false)

    const [subject, setSubject] = useState('')

    const inputImage = useRef(null)
    const [images, setImages] = useState([])

    const {isMobile} = useMatchMedia()

    const onSendMessage = () => {
        if (value.trim().length === 0) {
            return alert('Введите сообщение')
        }
        dispatch(sendMessage({
            id: ticketId,
            message: value,
            heading: subject,
            type: sendIn,
            images: images
        }))
        setValue('')
        setImages([])
    }

    const onEmojiClick = (e) => {
        setValue(prev => prev + e.emoji)
        setShowPicker(false)
    }

    const onCall = () => {
        if (!sessionStorage.getItem('atc')) alert('Проверьте номер ATC')
        else dispatch(ATCcall())
    }

    async function attachFile(file) {
        const resizedFile = await resizeFile(file)
        setImages(p => [...p, resizedFile])
    }

    const handleFileSelection = (e) => {
        if (e.target.files?.length) {
            [...e.target.files].forEach(file => {
                attachFile(file)
            })
            e.target.files = []
        }
    }

    const handlePaste = (e) => {
        if (e.clipboardData.files.length) {
            const fileObject = e.clipboardData.files[0];
            attachFile(fileObject)
        }
    };

    const handleImageRemove = (index) => {
        setImages((prev) => prev.filter((_, idx) => idx !== index))
    }

    if (!inputs) return null

    return <div style={isMobile ? {padding: 5} : {} } className={styles.footer}>
        <div className={styles.assetsBtns}>
            <img src={pinDown}
                 onClick={() => {
                     inputImage.current?.click?.()
                 }}
            />
            <div style={{position: 'relative'}}>
                <img onClick={() => setShowPicker(prev => !prev)} src={emoji}/>
                {showPicker &&
                    <div className={styles.emoji}><Picker onEmojiClick={onEmojiClick} emojiStyle={{width: '100%'}}/>
                    </div>}
            </div>
        </div>
        <div style={{flex: 2}}>
            <div className={styles.topTabs}>
                <div className={styles.topTabsLeft}>
                    <div onClick={() => setSendIn('2')}
                         className={cn(styles.tab, sendIn === '2' && styles.selectedTab, isCourier && 'hidden')}>{sendIn === '2' ?
                        <img src={waWhite}/> : <img src={waBlack}/>}</div>
                    <div onClick={() => setSendIn('3')}
                         className={cn(styles.tab, sendIn === '3' && styles.selectedTab, (isCourier || isSeason) && 'hidden')}>
                        <SText size={11}
                               weight={500}
                               color={sendIn === '3' ? '#fff' : Colors.darkGray}>
                            {'sms'}
                        </SText>
                    </div>
                    <div onClick={() => setSendIn('4')}
                         className={cn(styles.tab, sendIn === '4' && styles.selectedTab, (isCourier || isSeason) && 'hidden')}>
                        <SText size={11}
                               weight={500}
                               color={sendIn === '4' ? '#fff' : Colors.darkGray}>{'email'}
                        </SText>
                    </div>
                    <div onClick={() => setSendIn('5')}
                         className={cn(styles.tab, sendIn === '5' && styles.selectedTab, (isCourier || isSeason) && 'hidden')}>{<SText size={11}
                                                                                                  weight={500}
                                                                                                  color={sendIn === '5' ? '#fff' : Colors.darkGray}>{'моб. приложение'}</SText>}</div>
                    <div onClick={() => setSendIn('13')}
                         className={cn(styles.tab, sendIn === '13' && styles.selectedTab, !isCourier && 'hidden')}>
                        <SText size={11}
                               weight={500}
                               color={sendIn === '13' ? '#fff' : Colors.darkGray}>
                            {'кур. приложение'}
                        </SText>
                    </div>
                </div>
                {!isMobile && <div className={styles.topTabsRight}>
                    <div onClick={() => setTemplatesVisible(prev => !prev)}
                         style={{display: 'flex', alignItems: 'center', gap: 10}} className={styles.tab}>
                        <img src={plus}/>
                        <SText size={12} weight={500} color={Colors.darkGray}>{'Использовать шаблон'}</SText>
                    </div>
                    <div onClick={() => setModalVisible(true)} className={styles.ecoPointsBtn}>
                        <img src={ecoPointsWhite}/>
                        <SText div style={{marginRight: 4}} size={12} weight={700}
                               color={'#fff'}>{userInfo.activeScores}</SText>
                        <div className={styles.triangles}>
                            <div>
                                <img src={triangle}/>
                            </div>
                            <div><img style={{transform: 'rotate(180deg)'}} src={triangle}/></div>
                        </div>
                    </div>
                    <div onClick={onCall} className={cn(styles.callBtn, isSeason && 'hidden')}>
                        <img src={phone}/>
                        <SText size={12} weight={700} color={'#fff'}>{'Позвонить'}</SText>
                    </div>
                </div>}
                {templatesVisible && <Templates setChatValue={(text) => {
                    setValue(text)
                    setTemplatesVisible(false)
                }} onClose={() => setTemplatesVisible(false)}/>}
            </div>
            <div className={'flex flex-col gap-5'}>
                <div className={cn(images.length === 0 && 'hidden', styles.thumbsRow)}>
                    {images.map((img, idx) => <AttachedImage key={idx} image={img}
                                                             onRemove={() => handleImageRemove(idx)}/>)}
                </div>
                {sendIn === '4' && <TextField className={'bg-white rounded max-w-xs'}
                                              size={'small'}
                                              value={subject}
                                              onChange={e => setSubject(e.target.value)}
                                              label={'Тема сообщения'}
                />}
                <div className={'flex gap-3'}>
                    <TextField
                        className={'bg-white rounded flex-1'}
                        label={'Сообщение'}
                        multiline
                        minRows={2}
                        maxRows={5}
                        value={value}
                        onPaste={e => {
                            handlePaste(e)
                        }}
                        onChange={(e) => setValue(e.target.value)}
                    />
                    <Button variant={'contained'}
                            sx={{bgcolor: Colors.green}}
                            onClick={onSendMessage}
                            disabled={!value.length || (sendIn === '4' && !subject.length)}
                    >
                        <Send/>
                    </Button>
                </div>
                <input type={'file'}
                       style={{display: 'none'}}
                       ref={inputImage}
                       multiple={true}
                       onChange={handleFileSelection}
                       accept={"image/png, image/jpeg"}
                       id={'file'}/>
            </div>
        </div>
        {modalVisible && <EcoPointsModal onClose={() => setModalVisible(false)}/>}
    </div>
}

export default ChatFooter
