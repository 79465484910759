import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../api";
import moment from "moment";

const initialState = {
    calls: [],
    tickets: [],
    counts: {},
    lastUpdate: moment().format()
}

export const getTickets = createAsyncThunk(
    'tickets/getTickets',
    async ({ id, string = '', date = '', type = -1, status = -1 }) => {
        const params = new URLSearchParams({
            shopId: id,
            search: string,
            date,
            type: type == -1 ? '' : type,
            status: status == -1 ? 0 : status,
        }).toString()

        const rTicketsData = await instance.get(`tickets?${params}`).then(r => r.data.data)
        const rTickets = rTicketsData?.tickets
        const rCalls = rTicketsData?.pending_calls
        const rTicketsInfo = await instance.get('tickets/info').then(r => r.data.data)

        return { tickets: rTickets, counts: rTicketsInfo, calls: rCalls }
    }
)

export const confirmCall = createAsyncThunk(
    'tickets/confirm-pending-call',
    async (id) => {
        const response = await instance.post(`tickets/confirm-pending-call`, { id: id })
        getTickets()
        return response
    }
)

const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {},
    extraReducers: {
        [getTickets.fulfilled]: (state, action) => {
            state.tickets = action.payload.tickets
            state.counts = action.payload.counts
            state.calls = action.payload.calls
            state.lastUpdate = moment().format()
        },
    }
})

export default ticketsSlice.reducer
