import {Button, ClickAwayListener, TextField} from "@mui/material";
import styles from "../Chat.module.css";
import SText from "../../utils/SText";
import Colors from "../../utils/Colors";
import React, {useEffect, useState, useRef} from 'react'
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {
    clearComplaint,
    createComplaint,
    getClientInfo,
    getOrder,
    getOrderList
} from "../../../redux/reducers/complaint";
import {addUserBonuses, removeUserBonuses} from "../../../redux/reducers/chatInfo";
import moment from "moment";
import PreloaderModal from "../../common/PreloaderModal";
import {AttachedImage, resizeFile} from "./Footer";
import {Attachment} from "@mui/icons-material";

const Complaint = ({onClose, param: clientId}) => {
    const dispatch = useDispatch()
    const {
        orderProducts,
        orderId,
        address,
        scheduledTime,
        clientName,
        totalOrders,
        avgCheck,
        totalComplaint,
        total,
        dateTime,
        orderList,
        adminLink,
        finishDelivery,
        latestOrder: lastOrderId,
    } = useSelector(state => state.complaint)
    const {activeScores, donateScores} = useSelector(state => state.chatInfo.userInfo)
    const {user: {name, surname}} = useSelector(state => state.user)
    const productsActiveFormat = orderProducts.map(item => ({
        id: item.product_id,
        quality: 0,
        expired: 0,
        notInOrder: 0,
        mixed: 0,
        other_1: 0,
        other_2: 0,
    }))
    const [productsActive, setProductsActive] = useState(productsActiveFormat)
    const [delivery, setDelivery] = useState({
        dovoz: 0,
        crumpledGoods: 0,
        notCallClient: 0,
        badRussian: 0,
        wasImpolite: 0,
        askedCcancelOrder: 0,
    })
    const [delay, setDelay] = useState(null)
    const [orderListModal, setOrderListModal] = useState(false)
    const [comment, setComment] = useState('')
    const [ecoPoints, setEcoPoints] = useState({minus: '', plus: ''})

    const [images, setImages] = useState([])
    const inputImage = useRef(null)

    const lateness = moment(dateTime + ' ' + scheduledTime?.split('-')[1],`YYYY-MM-DD HH:mm`).diff(finishDelivery,'minutes')

    const toggle = (fieldName, id, index) => {
        let updated = [...productsActive]
        updated[index][fieldName] = productsActive[index][fieldName] === 0 ? 1 : 0
        setProductsActive(updated)
    }

    const changeOrderId = (id) => {
        dispatch(getOrder({id, clientId}))
    }

    async function attachFile(file) {
        const resizedFile = await resizeFile(file)
        setImages(p => [...p, resizedFile])
    }

    const handleFileSelection = (e) => {
        if (e.target.files?.length) {
            [...e.target.files].forEach(file => {
                attachFile(file)
            })
            e.target.files = []
        }
    }

    const handlePaste = (e) => {
        if (e.clipboardData.files.length) {
            const fileObject = e.clipboardData.files[0];
            attachFile(fileObject)
        }
    };

    const handleImageRemove = (index) => {
        setImages((prev) => prev.filter((_, idx) => idx !== index))
    }

    useEffect(() => {
        setProductsActive(productsActiveFormat)
    }, [orderProducts])

    useEffect(() => {
        dispatch(getClientInfo({clientId}))
        dispatch(getOrderList({clientId}))
        return () => dispatch(clearComplaint())
    }, [])

    useEffect(()=>{
        dispatch(getOrder({id: lastOrderId, clientId}))
    }, [lastOrderId])

    const onSubmit = () => {
        let sendData = {
            shopId: 2,
            orderId,
            userId: clientId,
            products: productsActive,
            delivery,
            images,
            delay: {
                "20": delay === 20 ? 1 : 0,
                "40": delay === 40 ? 1 : 0,
                "60": delay === 60 ? 1 : 0,
                "90": delay === 90 ? 1 : 0,
                "120": delay === 120 ? 1 : 0,
                "180": delay === 180 ? 1 : 0,
                "240": delay === 240 ? 1 : 0,
            },
            comment,
        }
        dispatch(createComplaint({sendData}))
        if (ecoPoints.plus !== '') dispatch(addUserBonuses({amount: ecoPoints.plus, reason: 'Баллы начислены в жалобе'}))
        if (ecoPoints.minus !== '') dispatch(removeUserBonuses({amount: ecoPoints.minus, reason: 'Баллы списаны в жалобе'}))
        setTimeout(() => {
            onClose()
        }, 200)
    }

    if (!orderProducts.length)
        return <PreloaderModal/>

    return  <div className={styles.complaintModalWrapper}>
                <div style={{marginBottom: 20}}>
                    <SText size={20} weight={700} lineHeight={20}>{'Создание жалобы'}</SText>
                </div>
                <div className={styles.tablesLine}>
                    <table className={cn(styles.table, styles.firstTable)}>
                        <tr>
                            <td><SText size={12} weight={500} color={'#fff'}>{'Номер заказа'}</SText></td>
                            <td style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: 'none',
                                position: 'relative',
                            }}>
                                <SText size={12} weight={900} color={'#5a5a5a'}>{orderId}</SText>
                                <SText onClick={() => setOrderListModal(prev => !prev)} div size={12} weight={700}
                                       color={'#ff7834'}
                                       style={{
                                           textDecoration: 'underline',
                                           cursor: 'pointer'
                                       }}>{'Поменять заказ'}</SText>
                                {orderListModal && <ClickAwayListener onClickAway={() => setOrderListModal(false)}>
                                    <div className={styles.orderListModal}>
                                        {
                                            orderList.map(item => <div key={item} onClick={() => {
                                                changeOrderId(item)
                                                setTimeout(() => {
                                                    setOrderListModal(false)
                                                }, 200)
                                            }} className={styles.orderListItem}><SText size={14}
                                                                                       weight={500}>{item}</SText>
                                            </div>)
                                        }
                                    </div>
                                </ClickAwayListener>}
                            </td>
                        </tr>
                        <tr>
                            <td><SText size={12} weight={500} color={'#fff'}>{'Принимает жалобу'}</SText></td>
                            <td><SText size={12} weight={500} color={'#5a5a5a'}>{name + ' ' + surname}</SText></td>
                        </tr>
                        <tr>
                            <td><SText size={12} weight={500} color={'#fff'}>{'Клиента'}</SText></td>
                            <td><SText size={12} weight={500} color={'#5a5a5a'}>{clientName}</SText></td>
                        </tr>
                        <tr>
                            <td><SText size={12} weight={500} color={'#fff'}>{'Кол-во жалоб от клиента'}</SText></td>
                            <td><SText size={12} weight={500} color={'#5a5a5a'}>{totalComplaint}</SText></td>
                        </tr>
                        <tr>
                            <td><SText size={12} weight={500} color={'#fff'}>{'Кол-во заказов'}</SText></td>
                            <td><SText size={12} weight={500} color={'#5a5a5a'}>{totalOrders}</SText></td>
                        </tr>
                        <tr>
                            <td><SText size={12} weight={500} color={'#fff'}>{'Средний чек'}</SText></td>
                            <td><SText size={12} weight={500} color={'#5a5a5a'}>{avgCheck + ' руб'}</SText></td>
                        </tr>
                    </table>
                    <table className={cn(styles.table, styles.secondTable)}>
                        <tr>
                            <td><SText size={12} weight={500} color={'#fff'}>{'Доставка'}</SText></td>
                            <td><SText size={12} weight={500} color={'#5a5a5a'}>{scheduledTime}</SText></td>
                        </tr>
                        <tr>
                            <td><SText size={12} weight={500} color={'#fff'}>{'Купьер отдал заказ в'}</SText></td>
                            <td style={{display: 'flex', alignItems: "center", border: 'none'}}>
                                <SText size={12}
                                       weight={500}
                                       color={'#5a5a5a'}>{finishDelivery.split(' ')[1]}</SText>
                                {lateness < 0 && <SText
                                    style={{textAlign: 'center', width: '100%'}} div size={12} weight={700}
                                    color={Colors.red}>{'опоздание ' + -lateness + ' мин'}</SText>}
                            </td>
                        </tr>
                        <tr>
                            <td><SText size={12} weight={500} color={'#fff'}>{'Адрес'}</SText></td>
                            <td>
                                <div><SText size={12} weight={500} color={'#5a5a5a'}>{address}</SText></div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style={{alignItems: 'flex-start'}} className={styles.tablesLine}>
                    <table className={cn(styles.table, styles.products)}>
                        <tr>
                            <th style={{borderTopColor: 'transparent', borderLeftColor: 'transparent'}}><SText size={18}
                                                                                                               weight={700}
                                                                                                               lineHeight={18}
                                                                                                               color={'#086FDE'}>{'Товары'}</SText>
                            </th>
                            <th style={{minWidth: 61}}><SText size={11} weight={500} lineHeight={11} color={'#fff'}
                                                              div>Цена <br/> на сайте <br/> (руб)</SText></th>
                            <th style={{minWidth: 60}}><SText size={11} weight={500} lineHeight={11} color={'#fff'}
                                                              div>После <br/> весов <br/> (руб)</SText></th>
                            <th style={{minWidth: 82}}><SText size={11} weight={500} lineHeight={11} color={'#fff'}
                                                              div>Разница <br/> (г/руб)</SText></th>
                            <th style={{minWidth: 68}}><SText size={11} weight={500} lineHeight={11}
                                                              color={'#fff'}>{'Качество'}</SText></th>
                            <th style={{minWidth: 68}}><SText size={11} weight={500} lineHeight={11}
                                                              color={'#fff'}>{'Просрочен'}</SText></th>
                            <th style={{minWidth: 68}}><SText size={11} weight={500} lineHeight={11}
                                                              color={'#fff'}>{'Пересортица'}</SText></th>
                            <th style={{minWidth: 68, maxWidth: 68}}><SText size={11} weight={500}
                                                                            lineHeight={11} color={'#fff'}
                                                                            div>Нет <br/> в <br/> заказе</SText>
                            </th>
                            <th style={{minWidth: 67}}><SText size={10} weight={500} lineHeight={10} color={'#fff'}
                                                              div>Непра- <br/>вильный <br/> состав <br/> Без <br/> маркировки</SText>
                            </th>
                            <th style={{minWidth: 68}}><SText size={9} weight={500} lineHeight={9} color={'#fff'}
                                                              div>Маленький <br/> калибр
                                или <br/> большой <br/> Невкусный <br/> Зелёный <br/> и прочее</SText></th>
                        </tr>
                        {
                            orderProducts?.map((item, index) => {
                                let isPositive = Number(item.diffWeight) >= 0

                                return <tr className={styles.activeCellRow} key={`${index} ${item.id}`}>
                                    <td><SText size={12} weight={500}
                                               lineHeight={15}>{(index + 1) + '. ' + item.title}</SText>
                                    </td>
                                    <td style={{minWidtd: 61, textAlign: 'center'}}><SText size={11} weight={500}
                                                                                           lineHeight={11}
                                                                                           div>{Math.round(item.price)}</SText>
                                    </td>
                                    <td style={{minWidtd: 60, textAlign: 'center'}}><SText size={11} weight={500}
                                                                                           lineHeight={11}
                                                                                           div>{Math.round(item.current_price)}</SText>
                                    </td>
                                    <td style={{minWidtd: 82, textAlign: 'center'}}>
                                        <SText size={11} weight={500} lineHeight={11}
                                               color={isPositive ? '#43B02A' : '#FF5252'}
                                               div>{(item?.diffWeight >= 0 ? '+' : '') +Math.floor(item.diffWeight) + '/' + (isPositive ? '+' : '') + Math.round(item.diffPrice)}</SText>
                                    </td>
                                    <td onClick={() => toggle('quality', item.id, index)} style={{minWidtd: 68}}>
                                        {
                                            productsActive[index]?.quality === 1 ?
                                                <div className={styles.activeCell}/> : null
                                        }
                                    </td>
                                    <td onClick={() => toggle('expired', item.id, index)} style={{minWidtd: 68}}>
                                        {
                                            productsActive[index]?.expired === 1 ?
                                                <div className={styles.activeCell}/> : null
                                        }
                                    </td>
                                    <td onClick={() => toggle('mixed', item.id, index)} style={{minWidtd: 68}}>
                                        {
                                            productsActive[index]?.mixed === 1 ?
                                                <div className={styles.activeCell}/> : null
                                        }
                                    </td>
                                    <td onClick={() => toggle('notInOrder', item.id, index)}
                                        style={{minWidtd: 68, maxWidth: 68, paddingLeft: 4}}>
                                        {
                                            productsActive[index]?.notInOrder === 1 ?
                                                <div className={styles.activeCell}/> : null
                                        }
                                    </td>
                                    <td onClick={() => toggle('other_1', item.id, index)} style={{minWidtd: 67}}>
                                        {
                                            productsActive[index]?.other_1 === 1 ?
                                                <div className={styles.activeCell}/> : null
                                        }
                                    </td>
                                    <td onClick={() => toggle('other_2', item.id, index)} style={{minWidtd: 68}}>
                                        {
                                            productsActive[index]?.other_2 === 1 ?
                                                <div className={styles.activeCell}/> : null
                                        }
                                    </td>
                                </tr>
                            })
                        }
                        <tr>
                            <td style={{
                                borderLeftColor: 'transparent',
                                borderBottomColor: 'transparent',
                                background: '#fff'
                            }}></td>
                            <td style={{minWidtd: 61, textAlign: 'center', padding: 5}}><SText size={11} weight={500}
                                                                                               lineHeight={11}
                                                                                               div>{Math.round(total?.totalPrice)}</SText>
                            </td>
                            <td style={{minWidtd: 60, textAlign: 'center', padding: 5}}><SText size={11} weight={500}
                                                                                               lineHeight={11}
                                                                                               div>{Math.round(total?.totalPriceAfterScale)}</SText>
                            </td>
                            <td style={{minWidtd: 82, textAlign: 'center', padding: 5}}><SText size={11} weight={500}
                                                                                               lineHeight={11}
                                                                                               div>{(total?.diffWeight >= 0 ? '+' : '') + Math.round(total?.diffWeight) + '/' + (total?.diffPrice >= 0 ? '+' : '') + Math.round(total?.diffPrice)}</SText>
                            </td>
                            <td style={{minWidtd: 68, paddingLeft: 4}}><SText size={11} weight={500} lineHeight={11}
                                                                              div style={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                border: "none",
                                paddingLeft: 0
                            }}>
                                <div/>
                                Сборщик </SText></td>
                            <td style={{minWidtd: 68, paddingLeft: 4}}><SText size={11} weight={500} lineHeight={11}
                                                                              div style={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                border: "none",
                                paddingLeft: 0
                            }}>
                                <div/>
                                Сборщик</SText></td>
                            <td style={{minWidtd: 68, paddingLeft: 4}}><SText size={11} weight={500} lineHeight={11}
                                                                              div style={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                border: "none",
                                paddingLeft: 0
                            }}>
                                <div/>
                                Сборщик</SText></td>
                            <td style={{minWidtd: 68, maxWidth: 68, paddingLeft: 2}}><SText size={11} weight={500}
                                                                                            lineHeight={11}
                                                                                            div>Без <br/> штрафа</SText>
                            </td>
                            <td style={{minWidtd: 67, paddingLeft: 4}}><SText size={11} weight={500} lineHeight={11}
                                                                              div style={{
                                display: "flex",
                                gap: 2,
                                alignItems: "center",
                                border: "none",
                                paddingLeft: 0
                            }}>
                                <div/>
                                Закупка</SText></td>
                            <td style={{minWidtd: 68, background: '#FFA067'}}><SText size={11} weight={500}
                                                                                     lineHeight={11} div>Без
                                штрафа</SText></td>
                        </tr>
                    </table>
                    <div style={{width: '100%'}}>
                        <div style={{paddingBottom: 5}}><SText size={11} lineHeight={11} weight={900}
                                                               color={'#086FDE'}>{'Доставка'}</SText></div>
                        <table style={{height: 'fit-content', width: '100%'}}
                               className={cn(styles.table, styles.deliveryDelay)}>
                            <tr>
                                <th style={{background: '#086FDE'}}><SText size={11} weight={500} lineHeight={11}
                                                                           color={'#fff'}>{'Помятый товар'}</SText></th>
                                <th><SText size={11} weight={500} lineHeight={11}
                                           color={'#fff'}>{'Не позвонил клиенту'}</SText>
                                </th>
                                <th><SText size={11} weight={500} lineHeight={11}
                                           color={'#fff'}>{'Плохо говорит по русски'}</SText></th>
                                <th><SText size={11} weight={500} lineHeight={11}
                                           color={'#fff'}>{'Был невежлив'}</SText>
                                </th>
                                <th><SText size={11} weight={500} lineHeight={11}
                                           color={'#fff'}>{'Просил отменить заказ'}</SText>
                                </th>
                                <th><SText size={11} weight={500} lineHeight={11}
                                           color={'#fff'}>{'Довоз'}</SText>
                                </th>
                            </tr>
                            <tr className={styles.activeCellRow}>
                                <td onClick={() => {
                                    setDelivery({...delivery, crumpledGoods: delivery.crumpledGoods === 0 ? 1 : 0})
                                }} style={{height: 28}}>
                                    {delivery.crumpledGoods === 0 ? null : <div className={styles.activeCell}/>}
                                </td>
                                <td onClick={() => {
                                    setDelivery({...delivery, notCallClient: delivery.notCallClient === 0 ? 1 : 0})
                                }} style={{height: 28}}>
                                    {delivery.notCallClient === 0 ? null : <div className={styles.activeCell}/>}
                                </td>
                                <td onClick={() => {
                                    setDelivery({...delivery, badRussian: delivery.badRussian === 0 ? 1 : 0})
                                }} style={{height: 28}}>
                                    {delivery.badRussian === 0 ? null : <div className={styles.activeCell}/>}
                                </td>
                                <td onClick={() => {
                                    setDelivery({...delivery, wasImpolite: delivery.wasImpolite === 0 ? 1 : 0})
                                }} style={{height: 28}}>
                                    {delivery.wasImpolite === 0 ? null : <div className={styles.activeCell}/>}
                                </td>
                                <td onClick={() => {
                                    setDelivery({
                                        ...delivery,
                                        askedCcancelOrder: delivery.askedCcancelOrder === 0 ? 1 : 0
                                    })
                                }} style={{height: 28}}>
                                    {delivery.askedCcancelOrder === 0 ? null : <div className={styles.activeCell}/>}
                                </td>
                                <td onClick={() => {
                                    setDelivery({...delivery, dovoz: delivery.dovoz === 0 ? 1 : 0})
                                }} style={{height: 28}}>
                                    {delivery.dovoz === 0 ? null : <div className={styles.activeCell}/>}
                                </td>
                            </tr>
                            <tr>
                                <td style={{background: '#fff'}}><SText size={11} weight={500}
                                                                        lineHeight={11}>{'Курьер'}</SText>
                                </td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Курьер'}</SText></td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Курьер'}</SText></td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Курьер'}</SText></td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Курьер'}</SText></td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Курьер'}</SText></td>
                            </tr>
                        </table>
                        <div style={{paddingBottom: 5}}><SText size={11} lineHeight={11} weight={900}
                                                               color={'#086FDE'}>{'Опоздание'}</SText></div>
                        <table style={{height: 'fit-content', width: '100%'}}
                               className={cn(styles.table, styles.deliveryDelay)}>
                            <tr>
                                <th style={{background: '#086FDE'}}><SText size={11} weight={500} lineHeight={11}
                                                                           color={'#fff'}>{'20 мин+'}</SText></th>
                                <th><SText size={11} weight={500} lineHeight={11} color={'#fff'}>{'40 мин+'}</SText>
                                </th>
                                <th><SText size={11} weight={500} lineHeight={11} color={'#fff'}>{'1 час+'}</SText></th>
                                <th><SText size={11} weight={500} lineHeight={11} color={'#fff'}>{'1,5 час+'}</SText>
                                </th>
                                <th><SText size={11} weight={500} lineHeight={11} color={'#fff'}>{'2 часа+'}</SText>
                                </th>
                                <th><SText size={11} weight={500} lineHeight={11} color={'#fff'}>{'3 часа+'}</SText>
                                </th>
                                <th><SText size={11} weight={500} lineHeight={11} color={'#fff'}>{'4 часа+'}</SText>
                                </th>
                            </tr>
                            <tr className={styles.activeCellRow}>
                                <td onClick={() => {
                                    setDelay(20)
                                }
                                } style={{height: 28}}>
                                    {delay === 20 ? <div className={styles.activeCell}/> : null}
                                </td>
                                <td onClick={() => {
                                    setDelay(40)
                                }
                                } style={{height: 28}}>
                                    {delay === 40 ? <div className={styles.activeCell}/> : null}
                                </td>
                                <td onClick={() => {
                                    setDelay(60)
                                }
                                } style={{height: 28}}>
                                    {delay === 60 ? <div className={styles.activeCell}/> : null}
                                </td>
                                <td onClick={() => {
                                    setDelay(90)
                                }
                                } style={{height: 28}}>
                                    {delay === 90 ? <div className={styles.activeCell}/> : null}
                                </td>
                                <td onClick={() => {
                                    setDelay(120)
                                }
                                } style={{height: 28}}>
                                    {delay === 120 ? <div className={styles.activeCell}/> : null}
                                </td>
                                <td onClick={() => {
                                    setDelay(180)
                                }
                                } style={{height: 28}}>
                                    {delay === 180 ? <div className={styles.activeCell}/> : null}
                                </td>
                                <td onClick={() => {
                                    setDelay(240)
                                }
                                } style={{height: 28}}>
                                    {delay === 240 ? <div className={styles.activeCell}/> : null}
                                </td>
                            </tr>
                            <tr>
                                <td style={{background: '#fff'}}><SText size={11} weight={500}
                                                                        lineHeight={11}>{'-'}</SText>
                                </td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'-'}</SText></td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Логист'}</SText></td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Логист'}</SText></td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Логист'}</SText></td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Логист'}</SText></td>
                                <td><SText size={11} weight={500} lineHeight={11}>{'Логист'}</SText></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className={styles.tablesLine}>
                    <input type={'file'}
                           style={{display: 'none'}}
                           ref={inputImage}
                           multiple={true}
                           onChange={handleFileSelection}
                           accept={"image/png, image/jpeg"}
                           id={'file'}/>
                    <div>
                        <div style={{marginBottom: 10}}><SText size={18} weight={700} lineHeight={18}
                                                               color={'#086FDE'}>{'Комментарий'}</SText></div>
                        <div className={cn(images.length === 0 && 'hidden', 'flex gap-3 my-2')}>
                            {images.map((img, idx) => <AttachedImage key={idx} image={img}
                                                                     onRemove={() => handleImageRemove(idx)}/>)}
                        </div>
                        <div className={'flex gap-2 flex-row-reverse'} style={{width: 803}}>
                            <Button variant={'outlined'} onClick={() => {
                                inputImage.current?.click?.()
                            }}>
                                <Attachment/>
                            </Button>
                            <TextField
                                value={comment}
                                multiline
                                minRows={2}
                                maxRows={6}
                                className={'flex-1 w-96'}
                                onPaste={e => {handlePaste(e)}}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <div style={{marginBottom: 10}}><SText size={18} weight={700} lineHeight={18}
                                                               color={'#086FDE'}>{'Экобаллы'}</SText></div>
                        <div>
                            <table style={{width: 'fit-content', marginBottom: 7}}
                                   className={cn(styles.table, styles.ecoTable)}>
                                <tr>
                                    <td style={{background: '#fff'}}><SText size={12} weight={500}
                                                                            lineHeight={12}>{'Менеджеры начислили ранее'}</SText>
                                    </td>
                                    <td><SText size={12} weight={500}
                                               lineHeight={12}>{`${donateScores?.totalScore} экобаллов`}</SText></td>
                                </tr>
                                <tr>
                                    <td style={{background: '#fff'}}><SText size={12} weight={500}
                                                                            lineHeight={12}>{'Всего на счету'}</SText>
                                    </td>
                                    <td><SText size={12} weight={500}
                                               lineHeight={12}>{`${activeScores} экобаллов`}</SText></td>
                                </tr>
                            </table>
                            <table style={{width: 'fit-content'}} className={cn(styles.table, styles.ecoTable)}>
                                <tr>
                                    <td style={{background: '#fff'}}><SText size={12} weight={700} lineHeight={12}
                                                                            color={'#34C759'}>{'Начислить экобаллы'}</SText>
                                    </td>
                                    <td style={{minWidth: 63, maxWidth: 100}}>
                                        <input value={ecoPoints.plus}
                                               onChange={(e) => setEcoPoints({...ecoPoints, plus: e.target.value})}
                                               className={styles.addEco} type="number"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{background: '#fff'}}><SText size={12} weight={700} lineHeight={12}
                                                                            color={'#FF5252'}>{'Списать экобаллы'}</SText>
                                    </td>
                                    <td style={{minWidth: 63, maxWidth: 100}}>
                                        <input value={ecoPoints.minus}
                                               onChange={(e) => setEcoPoints({...ecoPoints, minus: e.target.value})}
                                               className={styles.removeEco} type="number"/>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div className={styles.btnsWrapper}>
                    <a href={adminLink} target={'_blank'}><div className={styles.blackBtn}><SText size={12} weight={700} lineHeight={12}
                                                            color={'#fff'}>{'Открыть анкету клиента в админке'}</SText>
                    </div></a>
                    <div onClick={onSubmit} className={styles.greenComplaintBtn}><SText size={12} weight={700}
                                                                                        lineHeight={12}
                                                                                        color={'#fff'}>{'Отправить жалобу'}</SText>
                    </div>
                </div>
            </div>
}

export default Complaint
