import React, {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getToken} from "../../redux/reducers/user";
import styles from "../Chat/Chat.module.css";
import preloader from "../../assets/images/chat/preloader.gif";

const Auth = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const secretCode = searchParams.get('secret')
    const dispatch = useDispatch()

    useEffect(() => {
            dispatch(getToken(secretCode))
            if (secretCode)
                navigate('/')
    }, [])

    return <div>
        <div className={styles.preloader}>
            <img src={preloader}/>
        </div>
    </div>
}

export default Auth
