import React, {Fragment} from "react";
import {Route, Routes} from "react-router-dom";

import Chat from "../components/Chat/Chat";
import styles from "../components/Chat/Chat.module.css";

const MainRouter = () => {

    return <Routes>
        <Route path={'/'} element={<div className={styles.noChat}/>}/>
        <Route path={'/tickets/:id'} element={<Chat/>}/>
    </Routes>
}

export default MainRouter
