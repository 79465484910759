import bell from "../../../../assets/images/attachment/bell.svg";
import SText from "../../../utils/SText";
import React, { useMemo, useState } from "react";
import { PayloadMessageContainer } from "../Message";
import { useDispatch, useSelector } from "react-redux";
import {
    get1CBadgeProps,
    getOrderSource,
    getPaymentBadgeProps,
    getPaymentType,
    getStatusBadgeProps, prettyDateTimeSlot,
    roundPrice, useVisibility
} from "./utils";
import { selectProducts } from "../../../../redux/reducers/catalogues";
import { sendReplace } from "../../../../redux/reducers/chatInfo";
import { useQueryParams } from "../../../common/ModalSpectator";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import RedeemIcon from "@mui/icons-material/Redeem";
import { Add, PlusOne, Remove } from "@mui/icons-material";

import styles from "../message.module.css";

export const DataRow = ({ label, value }) => (
    !value ? null :
        <div className={ 'flex gap-1 items-baseline' }>
            <SText size={ 14 } weight={ 400 }>{ label + ': ' }</SText>
            <SText size={ 14 } weight={ 500 }>{ value }</SText>
        </div>
)

export const HeaderBadge = ({ text, color = '#007AFF' }) => {
    return <div className={ styles.orderHeaderBadge } style={ { backgroundColor: color } }>
        <SText size={ 13 } weight={ 500 } color={ '#fff' }>
            { text }
        </SText>
    </div>
}

const AgreementContent = ({ message }) => {
    const dispatch = useDispatch()
    const { push } = useQueryParams()

    const [amount, setAmount] = useState(1)
    const [selectedAction, setSelectedAction] = useState(false)
    const products = useSelector(selectProducts)
    const order = message.orderData
    const product = message.product

    const createAmountChanger = (delta) => () => {
        setAmount(amount + delta < 1 ? 1 : amount + delta)
    }

    const sendReplacement = () => {
        if (selectedAction == 'none') {
            dispatch(sendReplace({ replaceAction: selectedAction, shopId: order.shopId, messageId: message.message_id }, { dispatch }))
        } else {
            dispatch(sendReplace({ replaceAction: [{ id: selectedAction, amount: amount }], shopId: order.shopId, messageId: message.message_id }, { dispatch }))
        }
    }

    if (message.status == 1) {
        return <div style={ { width: '70px', margin: '5px 0px' } }><HeaderBadge text="Закрыто" /></div>
    }

    const actions = []
    if (message.replacements && message.replacements !== null) {
        message.replacements.map((product_id, index) => {
            const product = products[product_id]
            if (product) {
                actions.push(
                    <div key={ index } className={ styles.rowItem } onClick={ () => setSelectedAction(product_id) }>
                        <span className={ selectedAction == product.id ? styles.selectCircleSelected : styles.selectCircle }></span>
                        <span>{ product.title } / { product.price } руб</span>
                    </div>
                )
            }
        })
    }

    if (!actions.length) {
        actions.push(
            <div className={ styles.rowItem } key={ 0 }>Замен не предложено!</div>
        )
    }

    actions.push(
        <div key={ 'pureClose' } className={ styles.rowItem } onClick={ () => setSelectedAction('none') }>
            <span className={ selectedAction == 'none' ? styles.selectCircleSelected : styles.selectCircle }></span>
            <span>! Закрыть без замены</span>
        </div>
    )

    actions.push(
        <div key={ 'selectClose' } className={ styles.customInit } onClick={ () => push(['re', message.message_id + ',' + order.shopId]) }>
            Выбрать товар на замену
        </div>
    )

    return (
        <div className={ styles.agreementAnswer }>
            <div className={ styles.productName }>{ product.title }:</div>
            { actions }
            <div className={ styles.actionFooter }>
                <div className={ styles.agreeActions }>
                    <a className={ selectedAction ? styles.confirmBtn : styles.confirmBtnInactive } onClick={ () => selectedAction ? sendReplacement() : {} }>
                        Подтвердить
                    </a>
                </div>
                {
                    selectedAction > 0 &&
                    <div>
                        <div className={ 'flex flex-row items-center bg-blue-500 rounded-full' }>
                            <IconButton sx={ { color: '#fff' } } size={ 'small' } onClick={ createAmountChanger(-1) }>
                                <Remove />
                            </IconButton>
                            <SText weight={ 500 } size={ 13 } div className={ 'p-2' } color={ '#fff' }>{ amount + ' шт' }</SText>
                            <IconButton sx={ { color: '#fff' } } size={ 'small' } onClick={ createAmountChanger(+1) }>
                                <Add />
                            </IconButton>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

const Agreement = ({ message }) => {
    const order = message.orderData ?? message
    const adminUrl = order.adminUrl
    const { inputs, isSeason } = useVisibility()

    return <PayloadMessageContainer
        message={ message }
        meta={ { icon: bell, title: 'Согласование' } }
    >
        <a href={ adminUrl } target={ '_blank' } className={ styles.orderHeader }>
            <SText weight={ 500 }>
                { `Заказ ${order.label || order.id} ${isSeason ? 'ID: ' + order.id : ''}` }
            </SText>
            <HeaderBadge { ...getStatusBadgeProps(order) } />
            <HeaderBadge { ...getPaymentBadgeProps(order) } />
            <HeaderBadge { ...get1CBadgeProps(order) } />
        </a>
        { inputs &&
            <div className={ 'flex flex-col gap-2' }>
                <AgreementContent message={ message } />
            </div>
        }
    </PayloadMessageContainer>
}


export default Agreement
