import bell from "../../../../assets/images/attachment/bell.svg";
import React from "react";
import {PayloadMessageContainer} from "../Message";

const TicketAccept = ({message}) => {

    const fullName = [message.user?.name, message.user?.surname].join(' ')
    return <PayloadMessageContainer
        message={message}
        meta={{icon: bell, title: `${fullName} взял в работу`}}
    >

    </PayloadMessageContainer>
}

export default TicketAccept
