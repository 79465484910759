import styles from "../message.module.css";
import phoneWhite from "../../../../assets/images/attachment/phoneWhite.svg";
import SText from "../../../utils/SText";
import phoneGray from "../../../../assets/images/attachment/phoneGray.svg";
import React from "react";
import {PayloadMessageContainer} from "../Message";
import {useVisibility} from "./utils";
import {ATCcall} from "../../../../redux/reducers/chatInfo";
import {useDispatch} from "react-redux";

const MissedCall = ({message}) => {
    const {inputs} = useVisibility()
    const dispatch = useDispatch()

    const onCall = () => {
        if (!sessionStorage.getItem('atc')) alert('Проверьте номер ATC')
        else dispatch(ATCcall())
    }

    const HeaderControl = () => <button className={styles.callButton} onClick={onCall}>
        <img src={phoneWhite} alt={'phone-icon'}/>
        <SText size={12} weight={700} color={'#fff'}>{'Позвонить'}</SText>
    </button>

    return <PayloadMessageContainer message={message}
                                    meta={{icon: phoneGray, title: 'Пропущенный звонок от клиента'}}
                                    HeaderControls={!inputs ? false : HeaderControl}
    />
}

export default MissedCall
