import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styles from './CallAlert.module.css'
import phone from '../../assets/images/leftBar/phone.svg'
import whiteStar from '../../assets/images/leftBar/whiteStar.svg'
import searchImg from '../../assets/images/leftBar/search.svg'
import calendar from '../../assets/images/leftBar/calendar.svg'
import closeModal from '../../assets/images/closeModal.svg'
import motivationArrow from '../../assets/images/leftBar/motivationArrow.svg'
import demotivation from '../../assets/images/leftBar/demotivationArrow.svg'
import SText from "../utils/SText";
import { numberWithSpaces, onImgError } from "../utils/other";
import Colors from "../utils/Colors";
import { Box, ClickAwayListener, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux/reducers/user";
import { confirmCall } from "../../redux/reducers/tickets";
import { getCategories } from "../../redux/reducers/categories";
import { NavLink, useLocation } from 'react-router-dom'
import cn from "classnames";
import debounce from "lodash.debounce";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import { instance } from "../../api";
import { toast } from "react-toastify";
import Select from "react-select";
import { Filter, FilterAlt, FilterAltOff, Phone } from "@mui/icons-material";
import { useMatchMedia } from "../../hooks/hooks";

const CallAlert = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { tickets, calls } = useSelector(state => state.tickets)


    const callTicket = useMemo(() => {
        if (!calls.length) {
            return false
        }

        const current_phone = JSON.parse(sessionStorage.getItem('atc'))
        if (!current_phone) {
            return false
        }

        const call = calls.find(callData => parseInt(callData.inner_phone) == parseInt(current_phone))
        if (!call) {
            return false
        }

        return { link: '/tickets/' + call.ticket_id, call_id: call.id }
    }, [calls, tickets])

    const openTicketAndCloseCall = () => {
        dispatch(confirmCall(callTicket.call_id))
        navigate(callTicket.link)
    }

    if (!callTicket) return null

    return (
        <a className={ styles.link } onClick={ () => openTicketAndCloseCall() } >
            <img className={ styles.phoneIcon } src={ phone } />
        </a >
    )
}


export default CallAlert
