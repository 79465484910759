import React, {useEffect, useLayoutEffect, useState} from 'react'

export function useWindowDimensions() {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        handleResize()
        return () => {
            try {
                window.removeEventListener('resize', handleResize)
            } catch (e) {
            }

        };
    }, []);

    return width;
}

const queries = [
    '(max-width: 766px)',
    '(min-width: 767px)',
]

export const useMatchMedia = () => {
    const mediaQueryLists = queries.map(query => matchMedia(query))
    const getValues = () => mediaQueryLists.map(mq1 => mq1.matches)
    const [values, setValues] = useState(getValues)

    useLayoutEffect(() => {
        const handler = () => setValues(getValues)

        mediaQueryLists.forEach(mq1 => mq1.addEventListener('change', handler))

        return () => mediaQueryLists.forEach(mq1 => mq1.removeEventListener('change', handler))
    })

    return ['isMobile', 'isDesktop'].reduce((acc, screen, index) => ({
        ...acc,
        [screen]: values[index]
    }), {})
}