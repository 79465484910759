import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {instance} from "../../api";

const initialState = {
    categories: []
}

export const getCategories = createAsyncThunk(
    'categories/getCategories',
    async () => {
        const response = await instance.get('tickets/categories')
        return response?.data?.data?.categories
    }
)

const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers: {
        [getCategories.fulfilled]: (state, action) => {
            state.categories = action.payload
        }
    }
})

export default categoriesSlice.reducer
