import {useDispatch, useSelector} from "react-redux";
import React from "react";
import styles from "../Chat.module.css";
import userZaglushka from "../../../assets/images/leftBar/lk.svg";
import SText from "../../utils/SText";
import Colors from "../../utils/Colors";
import grayStar from "../../../assets/images/chat/grayStar.svg";
import ecoPoints from "../../../assets/images/chat/ecoPoints.svg";
import ClientRating from "./ClientRating";
import {useQueryParams} from "../../common/ModalSpectator";
import {acceptTicket, cancelTicket, closeTicket} from "../../../redux/reducers/chatInfo";
import {getVisibilityConfig, useVisibility} from "./MessageTypes/utils";
import cn from "classnames";
import {onImgError} from "../../utils/other";
import {useMatchMedia} from "../../../hooks/hooks";

const ChatHeader = () => {

    const userData = useSelector(state => state.chatInfo)
    const {push} = useQueryParams()
    const dispatch = useDispatch()

    const {userInfo} = useSelector(state => state.chatInfo)
    const {inputs, canAccept, canClose, isCourier, isSeason} = useVisibility()
    const {isMobile} = useMatchMedia()
    const {tickets} = useSelector(state => state.tickets)
    const phone = tickets?.filter(item => item.id === userData?.ticket?.id)[0]?.user_data?.phone || null

    const counts = useSelector(state => state.tickets.counts)
    const managerTickets = counts.managerTickets

    const onAcceptTicket = () => {
        dispatch(acceptTicket())
    }

    const onCloseTicket = () => {
        dispatch(closeTicket())
    }

    const onCancelTicket = () => {
        dispatch(cancelTicket())
    }

    return <div className={isMobile ? styles.chatHeaderWrapperMobile : styles.chatHeaderWrapper}>

        <div className={isMobile ? styles.clientInfoMobile : styles.clientInfo}>
            <a href={userInfo.adminUrl} target={'_blank'}>
                <div style={isMobile ? {height: 20, width: 20} : {} } className={styles.userZaglushka}>
                    {userData?.userInfo?.photo ?
                        <img style={{width: isMobile ? 20 : 50, height: isMobile ? 20 : 50, borderRadius: '50%', objectFit: 'cover'}}
                             src={userData?.userInfo?.photo}/> : <img src={userZaglushka}/>}
                </div>
            </a>
            <div>
                {userData?.ticket?.user_id !== 0 && <a href={userInfo.adminUrl} target={'_blank'} style={{display: 'flex', alignItems: 'center', gap: 5}}
                    className={isMobile ? styles.clientNameMobile : styles.clientName}>
                    <SText size={14} lineHeight={14} weight={700}
                           color={Colors.darkGray}>{(userData.userInfo.full_name || 'Без имени') + ' '}</SText>
                    {!!userData?.userInfo?.avgRate && !isMobile &&
                        <div className={'flex gap-1'}>
                            <img src={grayStar}/>
                            <SText size={12} lineHeight={14} weight={700}
                                   color={'#9a9a9a'}>{Number(userData?.userInfo?.avgRate).toFixed(1)}</SText>
                        </div>}
                </a>}
                {userData?.ticket?.user_id !== 0 && <div className={styles.clientAddress}>
                    <SText size={12} weight={500}
                           color={Colors.darkGray}>{userData?.userInfo?.addresses?.[0]?.address}</SText>
                </div>}
                {userData?.ticket?.user_id !== 0 && <div>
                    <SText size={12} weight={500} lineHeight={12}
                           color={Colors.darkGray}>{userData?.userInfo?.phone}</SText>
                    <SText size={12} weight={500} lineHeight={12}
                           color={Colors.darkGray}>{' ' + userData?.userInfo?.email}</SText>
                </div>}
                {phone && userData?.ticket?.user_id === 0 && <div>
                    <SText size={12} weight={500} lineHeight={12}
                           color={Colors.darkGray}>{phone}</SText>
                </div>}
            </div>
        </div>

        <div className={cn(isMobile ? styles.clientComplaintMobile : styles.clientComplaint, isCourier && 'hidden')}>
            {userData?.ticket?.user_id !== 0 && <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 3}}>
                <a href={userInfo?.ordersUrl}
                   className={'flex gap-1 underline'} target={'_blank'}>
                    <SText size={12} lineHeight={12} weight={500} color={Colors.darkGray}>
                        {'Количество заказов: '}
                    </SText>
                    <SText lineHeight={12} size={12}
                           weight={700}
                           color={Colors.darkGray}>{userData?.userInfo?.totalOrderCount}</SText>
                </a>
                <div className={'flex gap-1'}>
                    <SText size={12} lineHeight={12} weight={500} color={Colors.darkGray}>
                        {'Средний чек: '}
                    </SText>
                    <SText size={12} lineHeight={12}
                           weight={700}
                           color={Colors.darkGray}>{Math.round(userData?.userInfo?.avgCheck) + ' ₽'}</SText>
                </div>
            </div>}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {!isMobile && <div onClick={() => push(['cl', userData.userInfo.id])}
                      className={cn(styles.complaintBtn, (!inputs || isSeason) && 'hidden')}>
                    <SText size={12} weight={500}
                           color={'#fff'}>{'Жалоба'}</SText>
                </div>}
                <div style={{width: 100}} onClick={() => push(['change', userData.userInfo.id])}
                     className={cn(styles.complaintBtn, (!inputs || isSeason) && 'hidden')}>
                    <SText size={12} weight={500}
                           color={'#fff'}>{'Др. менеджер'}</SText>
                </div>
            </div>
            {(!inputs && userData.dispatcherInfo) &&
                <div className={'flex gap-3 mt-3 items-center bg-gray-200 rounded-md p-1'}>
                    <img src={userData.dispatcherInfo?.photo}
                         onError={onImgError}
                         className={'w-10 h-10 rounded-full object-cover'}/>
                    <div className={'flex flex-col gap-1'}>
                        <SText size={12} weight={400}>{'Работает:'}</SText>
                        <SText size={12} weight={500}>{userData.dispatcherInfo?.full_name}</SText>
                    </div>
                </div>}
        </div>

        <div style={isMobile ? {padding: 5} : {}} className={styles.clientRating}>
            {!isMobile && <div className={cn((isCourier || isSeason) && 'hidden')}>
                <div style={{marginBottom: 10}}><SText size={14} color={Colors.darkGray}
                                                       weight={500}>{'Оцените клиента'}</SText></div>
                <ClientRating/>
            </div>}
            <div className={isCourier ? 'flex-1' : 'hidden'}/>
            <div style={(isMobile && canClose) ? {display: 'flex', alignItems: "center", gap: 10, justifyContent: "space-between", width: '100%'} : {} } className={cn(!canClose && 'hidden')}>
                {/* <div onClick={onCancelTicket} style={isMobile ? {} : {marginBottom: 5}} className={styles.mainBtn}>
                    <SText size={14} weight={700} color={'#fff'}>{'Отменить'}</SText>
                </div> */}
                <div onClick={onCloseTicket} className={styles.mainBtn}>
                    <SText size={14} weight={700} color={'#fff'}>{'Закрыть'}</SText>
                </div>
            </div>
            <div onClick={onAcceptTicket} className={cn(styles.mainBtn, !canAccept && 'hidden')}>
                <SText size={14} weight={700} color={'#fff'}>{'Взять в работу'}</SText>
            </div>
        </div>
    </div>
}

export default ChatHeader
