import email from "../../../../assets/images/attachment/email.svg";
import SText from "../../../utils/SText";
import React from "react";
import {PayloadMessageContainer} from "../Message";

const MessagePush = ({message}) => {

    return <PayloadMessageContainer message={message}
                                    meta={{icon: email, title: 'Отправлен Push'}}
    >
        <div className={'flex flex-col gap-2'}>
            <SText size={12} weight={500}>{message.heading}</SText>
            <SText size={12} weight={400}>{message.text}</SText>
        </div>
    </PayloadMessageContainer>
}


export default MessagePush
