import React from 'react';
import SText from "../../utils/SText";
import {Button, Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import {instance} from "../../../api";
import {updateOrders} from "../../../redux/reducers/chatInfo";
import {toast} from "react-toastify";

const OrderCancel = ({onClose, param}) => {
    const [shopId, orderId, orderLabel] = param.split(',')
    const dispatch = useDispatch()

    async function onCancel () {
        const response = await instance.post('order/cancel', {shopId, orderId}).then(r=>r.data)
        const ok = response.result === 'ok'
        toast[ok ? 'success' : 'warn'](response.message)

        dispatch(updateOrders({force: true}))
        onClose()
    }

    return <div className={'flex-1 flex flex-col p-5'}>
        <div className={'flex-1 flex items-center justify-center'}>
            <Typography variant={'body1'}>{'Вы действительно хотите отменить заказ '+orderLabel+'?'}</Typography>
        </div>
        <div className={'flex gap-3 justify-end'}>
            <Button variant={'contained'} onClick={onClose}>Не отменять</Button>
            <Button color={'warning'} onClick={onCancel} variant={'contained'}>Да, отменить заказ</Button>
        </div>
    </div>
}

export default OrderCancel
