import React, {useState, useRef, useEffect} from 'react';
import ImageViewer from "react-simple-image-viewer";
import {createPortal} from "react-dom";

const Preview = ({file, onClose}) => {
    const el = useRef(document.createElement('div')).current

    useEffect(()=>{
        el.setAttribute("id", "image_preview")
        document.body.appendChild(el)

        return () =>  el.remove()
    }, [el])

    const child = <ImageViewer
        src={[file]}
        currentIndex={0}
        backgroundStyle={{backgroundColor: '#000000cc'}}
        leftArrowComponent={() => null}
        rightArrowComponent={() => null}
        disableScroll={true}
        closeOnClickOutside={true}
        onClose={onClose}/>

    return createPortal(
        child, el
    )
}

const ImagePreview = ({...props}) => {
    const [viewerVisible, setViewerVisible] = useState(false)

    const open = () => setViewerVisible(true)

    const close = () => setViewerVisible(false)

    return <>
        <img {...props} style={{cursor: 'pointer', ...props.style}} onClick={(e)=>{
            props.onClick?.(e)
            open()
        }}/>
        {viewerVisible &&
            <Preview file={props.src} onClose={close}/>
        }
    </>
}

export default ImagePreview

