import React from 'react';
import {CircularProgress} from "@mui/material";

const PreloaderModal = ({}) => {

    return <div className={'flex w-full flex-1 items-center justify-center'}>
        <CircularProgress size={30}/>
    </div>
}

export default PreloaderModal
