import Colors from "../../../utils/Colors";
import moment from "moment";
import {useSelector} from "react-redux";

export function getStatusBadgeProps(message) {
    switch (message.status) {
        case 'canceled':
            return {
                color: Colors.mediumGray,
                text: 'Отменён'
            }
        case 'created':
            return {
                color: Colors.green,
                text: 'Новый'
            }
        case 'handed_over_for_picking': return {
            color: Colors.orange,
            text: 'Передан на сбор'
        }
        case 'on_the_way': return {
            color: Colors.orange,
            text: 'В пути'
        }
        case 'delivered':
            return {
                color: Colors.green,
                text: 'Доставлен'
            }

        default:
            return {
                color: Colors.red,
                text: 'status: ' + message.status
            }
    }
}

export function getPaymentBadgeProps(message) {
    switch (message.status_payment+'') {
        case '0':
            return {
                color: Colors.red,
                text: 'Не оплачен'
            }
        case '1':
            return {
                color: Colors.green,
                text: 'Оплачен'
            }
        case '2': case '3': return {
            color: Colors.green,
            text: 'Средства зарезервированы'
        }
        default: {
            return {
                color: Colors.red,
                text: 'status_payment: ' + message.status_payment
            }
        }
    }
}

export function get1CBadgeProps(order) {
    switch (order['status1c']) {
        default:
            return {text: '1C: '+(order['status1c'] ?? '-'), color: Colors.orange}
    }
}

export function prettyDateTimeSlot (date, timeSlot) {
    return moment(date, 'YYYY-MM-DD').format('LL') + ' ' + timeSlot
}

export function getOrderSource(order) {
    switch (order.source) {
        case '0':
            return 'Сайт'
        case '1': case "2":
            return 'Приложение'
        default:
            return order.source
    }
}

export function getPaymentType(order) {
    switch (order.type_payment) {
        case '1':
            return 'Наличными'
        case '3':
            return 'Банковской картой'
        default:
            return order.type_payment
    }
}

export function numberWithSpaces(x) {
    let parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}


export function roundPrice(_price, withRub4ik=false) {
    const price = Number(_price)
    const rub4ik = withRub4ik ? ' ₽' : ''

    if (Math.abs(price) % 1 < 0.005)
        return numberWithSpaces(Math.floor(price))+rub4ik
    else
        return numberWithSpaces(price.toFixed(2)).replace('.', ',')+rub4ik
}

export function getOrderSum(order, withRub = false) {
    return roundPrice(order.full_amount) + (withRub ? ' ₽' : '')
}

export function valuesToOptions (valuesArray) {
    return valuesArray.map(value=>{
        return {
            value: value,
            label: value
        }
    })
}


export function getVisibilityConfig (ticket, user) {
    const statuses = {
        0: 'new',
        1: 'pending',
        2: 'closed',
        3: 'closed'
    }

    const isCourier = ticket.type === 13
    const isSeason = ticket.shop_id === 1

    const isMine = (ticket.manager_id?.toString() === user.id?.toString())
    const status = statuses[ticket.status]


    return {
        inputs: isMine && status === 'pending',
        canAccept: status === 'new' || status === 'closed',
        canClose: isMine && status === 'pending',
        isCourier, isSeason
    }
}

export function useVisibility () {
    const {ticket} = useSelector(state => state.chatInfo)
    const dispatcher = useSelector(state=>state.user.user)

    return getVisibilityConfig(ticket, dispatcher)
}
