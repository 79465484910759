import React, { useEffect, useMemo, useState } from 'react';
import SText from "../utils/SText";
import styles from './styles.module.css'
import Product, { ProductId } from "./components/Product";
import { useQuery } from "@tanstack/react-query";
import { ECO_BASE_DOMAIN, ECO_BASE_URL, instance } from "../../api";
import PreloaderModal from "../common/PreloaderModal";
import { Autocomplete, Box, Button, CircularProgress, TextField } from "@mui/material";
import { Add } from "@mui/icons-material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateOrders } from "../../redux/reducers/chatInfo";
import { selectProducts } from "../../redux/reducers/catalogues";
import { sendReplace } from "../../redux/reducers/chatInfo";

const ReplaceCustom = ({ param, onClose }) => {
    const [messageId, shopId] = param.split(',')
    const products = useSelector(selectProducts)

    const [productToAdd, setProductToAdd] = useState(false)
    const [addedProducts, setAddedProducts] = useState({})
    const dispatch = useDispatch()

    const [searchQuery, setSearchQuery] = useState('')

    const { data: searchResults, isLoading: isSearchLoading } = useQuery({
        queryKey: ['productsSearch', searchQuery],
        queryFn: () => {
            if (searchQuery?.length < 3)
                return []
            return axios
                .get(`https://search.ecomarket.ru/index.php?r=site/old&query=${searchQuery}&categories=0&limit=18&location=77`)
                .then(r => r.data?.results?.ids)
        }
    })


    function onSubmit() {
        const productsToSend = [];
        for (let key in addedProducts) {
            productsToSend.push({
                id: addedProducts[key].productId,
                amount: addedProducts[key].amount,
                price: addedProducts[key].price,
            })
        }
        dispatch(sendReplace({ replaceAction: productsToSend, shopId: shopId, messageId: messageId }, { dispatch }))
        onClose()
        return
        ///
        const payload = {
            shopId,
            //orderId,
            products: Object.values(addedProducts)
        }
        instance.post('order/additional-sale', payload).then(r => r.data)
            .then((response) => {
                response.result === 'ok' ?
                    toast.success('Товары успешно добавлены') :
                    toast.warn(response.message)
                setAddedProducts({})
                setProductToAdd({})
                dispatch(updateOrders())
                onClose()
            })
    }

    const options = useMemo(() => {
        if (!searchResults) return []
        const results = []
        searchResults.map(id => {
            const product = products[id]
            if (product) {
                results.push({
                    id,
                    name: product.title,
                    image: ECO_BASE_DOMAIN + product.thumb,
                    price: product.price,
                    available: product.available
                })
            }
        })
        return results
    }, [searchResults])

    function onAddProduct() {
        setAddedProducts(prev => {
            const id = productToAdd.id
            const copy = { ...prev }
            copy[id] = {
                price: productToAdd.price,
                amount: (copy[id]?.amount ?? 0) + 1,
                productId: id
            }
            return copy
        })
        setProductToAdd(false)
        setSearchQuery('')
    }

    function handleAmountChange(id, newAmount) {

        setAddedProducts(prev => {
            const copy = { ...prev }
            if (newAmount === 0) {
                delete copy[id]
                return copy
            }
            copy[id] = {
                ...copy[id],
                amount: newAmount,
            }
            return copy
        })
    }

    function handlePriceChange(id, newPrice) {
        setAddedProducts(prev => {
            const copy = { ...prev }
            copy[id] = {
                ...copy[id],
                price: newPrice,
            }
            return copy
        })
    }

    return <div className={ styles.body }>
        <div className={ 'mb-4' }>
            <SText size={ 18 } weight={ 700 }>{ 'Товары на замену' }</SText>
        </div>
        <div className={ 'flex-1 mb-4 flex flex-col gap-3 pb-3' } style={ { borderBottom: 'solid 1px black' } }>
            { Object.values(addedProducts).map((product) => {
                return <ProductId id={ product.productId }
                    amount={ product.amount }
                    price={ product.price }
                    onAmountChange={ handleAmountChange }
                    onPriceChange={ handlePriceChange }
                />
            }) }
            <div className={ 'flex gap-2 mt-10' }>
                <Autocomplete
                    inputValue={ searchQuery }
                    noOptionsText={ 'Начните вводить имя продукта' }
                    className={ 'flex-1' }
                    renderOption={ renderOption }
                    getOptionLabel={ option => option.name }
                    filterOptions={ x => x }
                    onInputChange={ (event, newInputValue) => {
                        setSearchQuery(newInputValue);
                    } }
                    onChange={ (event, newValue) => {
                        setProductToAdd({ id: newValue.id, price: newValue.price })
                    } }
                    renderInput={ (params) => (
                        <TextField { ...params }
                            InputProps={ {
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        { isSearchLoading ? <CircularProgress color="inherit" size={ 20 } /> : null }
                                        { params.InputProps.endAdornment }
                                    </React.Fragment>
                                ),
                            } }
                            label="Добавить продукт"
                        />) }
                    options={ options }
                />
                <Button variant={ 'contained' } disabled={ !productToAdd } onClick={ onAddProduct }>
                    <Add />
                </Button>
            </div>
        </div>
        <div className={ 'h-10 mt-10 flex justify-end gap-6' }>
            <div className={ 'flex flex-col justify-center' }>
                <SText size={ 20 } weight={ 700 }>{ `Итого: ${Object.values(addedProducts)
                    .reduce((sum, p) => { return sum + (p.price * p.amount) }, 0)} ₽` }</SText>
            </div>
            <div className={ 'flex-col flex justify-center' }>
                <Button variant={ 'contained' }
                    onClick={ onSubmit }
                    disabled={ !Object.keys(addedProducts).length }>
                    { 'Сохранить' }
                </Button>
            </div>
        </div>
    </div>
}


const renderOption = (props, option) => {


    return <Box { ...props }>
        <div className={ 'flex gap-3 items-center' }>
            <img className={ 'w-10 h-10 rounded bg-red-300' } src={ option.image } />
            <SText size={ 16 } weight={ 500 }>{ option.name }</SText>
            <SText size={ 16 } weight={ 400 }>{ option.price } руб  </SText>
            <SText size={ 16 } weight={ 400 }>(на складе { option.available } шт)</SText>
        </div>
    </Box>
}


export default ReplaceCustom
