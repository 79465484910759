import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {instance} from "../../api";
import {warn} from "./chatInfo";
import {toast} from "react-toastify";

const initialState = {
    orderProducts: [],
    orderId: null,
    address: '',
    scheduledTime: null,
    dateTime: null,
    finishDelivery: null,
    clientName: '',
    totalOrders: 0,
    adminLink: '',
    avgCheck: 0,
    totalComplaint: 0,
    total: {
        totalPrice: '',
        totalPriceAfterScale: '',
        diffWeight: '',
        diffPrice: '',
    },
    orderList: [],
    latestOrder: ''
}

export const getClientInfo = createAsyncThunk(
    'complaint/getClientInfo',
    async ({clientId}) => {
        const response = await instance.get(`client/get-client-info?shopId=2&userId=${clientId}`)
        return response?.data?.data
    }
)

export const getOrder = createAsyncThunk(
    'complaint/getOrder',
    async ({id, clientId}) => {
        const response = await instance.get(`client/get-order?shopId=2&userId=${clientId}&orderId=${id}`)
        return response?.data?.data
    }
)

export const getOrderList = createAsyncThunk(
    'complaint/getOrderList',
    async ({clientId}) => {
        const response = await instance.get(`client/get-order-list?shopId=2&orderBy=DESC&limit=10&offset=0&userId=${clientId}`)
        return response?.data?.data?.orders
    }
)

export const createComplaint = createAsyncThunk(
    'complaint/createComplaint',
    async ({sendData}) => {
        const response = await instance.post(`tickets/complaint/create`, {...sendData})
        warn(response)
        if (response.data.result === 'ok')
            toast.success('Жалоба успешно создана')

    }
)

const complaintSlice = createSlice({
    name: 'complaint',
    initialState,
    reducers: {
        clearComplaint: (state) => {
            state.orderProducts = []
            state.orderId = null
            state.address = ''
            state.scheduledTime = null
            state.clientName = ''
            state.totalOrders = 0
            state.avgCheck = 0
            state.totalComplaint = 0
            state.total = {
                totalPrice: '',
                totalPriceAfterScale: '',
                diffWeight: '',
                diffPrice: '',
            }
        },
        clearOrderList: (state) => {
            state.orderList = []
        },
    },
    extraReducers: {
        [getClientInfo.fulfilled]: (state, action) => {
            state.clientName = action.payload.full_name
            state.totalOrders = action.payload.totalOrderCount
            state.avgCheck = Math.floor(action.payload.avgCheck)
            state.totalComplaint = action.payload.totalComplaint
            state.adminLink = action.payload.adminUrl
        },
        [getOrder.fulfilled]: (state, action) => {
            state.orderProducts = action.payload.orderProducts
            state.address = action.payload.address
            state.scheduledTime = action.payload.time_interval
            state.total = action.payload.total
            state.orderId = action.payload.id
            state.finishDelivery = action.payload.finish_delivery
            state.dateTime = action.payload.date_interval
        },
        [getOrderList.fulfilled]: (state, action) => {
            let orderList = action.payload.map(item => item.id)
            state.orderList = orderList
            state.latestOrder = orderList[0]
        },
        [getOrderList.pending]: (state, action) => {
            state.orderList = []
        }
    }
})

export const {clearComplaint, clearOrderList} = complaintSlice.actions
export default complaintSlice.reducer
