import phoneGray from "../../../../assets/images/attachment/phoneGray.svg";
import React from "react";
import {PayloadMessageContainer} from "../Message";
import {DataRow} from "./Order";

const CallRecord = ({message}) => {
    return <PayloadMessageContainer message={message}
                                    meta={{icon: phoneGray, title: 'Звонок из АТС'}}
    >
        <div className={'flex flex-col gap-2'}>
            <DataRow label={'Внутренний номер'} value={message.innerPhone}/>
            <audio preload={'auto'} autobuffer={"true"} controls className={'bg-transparent'}>
                <source src={message.source}/>
            </audio>
        </div>
    </PayloadMessageContainer>
}

export default CallRecord
