import React, {useEffect, useMemo, useState} from 'react';
import {PayloadMessageContainer} from "../Message";
import transferSVG from '../../../../assets/images/attachment/transfer.svg'
import styles from "../message.module.css";
import SText from "../../../utils/SText";
import Colors from "../../../utils/Colors";
import {DataRow, HeaderBadge, OrderControls} from "./Order";
import ruble from "../../../../assets/images/attachment/rubleWhite.svg";
import card from "../../../../assets/images/attachment/card.svg";
import cart from '../../../../assets/images/attachment/cart.svg'
import {Button} from "@mui/material";
import {
    get1CBadgeProps,
    getOrderSource,
    getOrderSum,
    getPaymentBadgeProps,
    getStatusBadgeProps,
    roundPrice, useVisibility,
    valuesToOptions
} from "./utils";
import {useForm} from "react-hook-form";
import {ConnectedInput, ConnectedSelect} from "../../../common/ConnectedInputs";
import {getDeliverySlots} from "../../../../api";
import {useQuery} from "@tanstack/react-query";
import {useQueryParams} from "../../../common/ModalSpectator";
import {useDispatch} from "react-redux";
import {sendCardLink, sendPayLink, transferOrder, updateOrders} from "../../../../redux/reducers/chatInfo";
import {EditLocationAlt} from "@mui/icons-material";


const paymentOptions = [
    {value: "1", label: 'Наличными курьеру'},
    {value: "3", label: 'Банковской картой'}
]

const OrderTransfer = ({message}) => {

    const order = message.orderData ?? message

    const {inputs, isSeason} = useVisibility()


    const dispatch = useDispatch()
    const {data: slotsData, error, isLoading} = useQuery({
        queryKey: ['slots'],
        retry: 2,
        queryFn: () => getDeliverySlots({lat: order.lat, lon: order.lon})
    })

    const {push} = useQueryParams()

    const {control, handleSubmit, watch, reset, setValue, getValues} = useForm({
        defaultValues: {
            address: order.address,
            time_interval: order.time_interval,
            date_interval: order.date_interval,
            type_payment: order.type_payment,
            delivery_price: Number(order.delivery_price),
            flat: order.flat, entrance: order.entrance, floor: order.floor,
            comment: order.comment,
            dispatcher_comment: '',
            lat: order.lat,
            lon: order.lon,
        }
    })

    function changeLocation () {
        function callback (name, coords) {
            setValue('address', name)
            setValue('lat', coords[0])
            setValue('lon', coords[1])
        }
        window.onLocationSubmit = callback

        const currentCoords = getValues(['lat', 'lon'])
        push(['pl', currentCoords.join(',')])
    }

    async function onSubmit (data) {
        const payload = {
            shopId: 1, //todo
            ...order,
            ...data
        }
        dispatch(transferOrder(payload))
    }


    const selectedDate = watch('date_interval')


    const dateTimeOptions = useMemo(() => {
        let extraDates = [], extraTimes = []

        if (slotsData?.timeSlots) {
            extraDates = Object.keys(slotsData.timeSlots)
            extraTimes = slotsData.timeSlots[selectedDate]?.times || []
        }

        const dateOptions = [order.date_interval, ...extraDates]
        const timeOptions = [order.time_interval, ...extraTimes]

        return {timeOptions: valuesToOptions(timeOptions), dateOptions: valuesToOptions(dateOptions)}
    }, [order, selectedDate, slotsData])

    const adminUrl = order.adminUrl

    function onSendPayLink () {
        dispatch(sendPayLink({orderId: order.id, shopId: order.shopId}))
    }
    function onSendCardLink () {
        dispatch(sendCardLink({orderId: order.id, shopId: order.shopId}))
    }


    return <PayloadMessageContainer
        message={message}
        large meta={{title: 'Перевод заказа', icon: transferSVG}}
        HeaderControls={() => <OrderControls order={order}/>}
    >
        <form onSubmit={e=>handleSubmit(onSubmit)(e)}>
            <a href={adminUrl} target={'_blank'} className={styles.orderHeader}>
                <SText weight={500}>
                    {`Заказ ${order.label || order.id} ${isSeason ? 'ID: '+order.id : ''}`}
                </SText>
                <HeaderBadge {...getStatusBadgeProps(order)}/>
                <HeaderBadge {...getPaymentBadgeProps(order)}/>
                <HeaderBadge {...get1CBadgeProps(order)}/>
            </a>
            {(!!inputs && order.status_payment == 0) && <div className={styles.orderButtons}>
                <button type={"button"}>
                    <img src={ruble} onClick={onSendPayLink}/>
                    <SText color={'#fff'} size={12} weight={500}>{'Отпр. ссылку на оплату'}</SText>
                </button>
                <button type={"button"} onClick={onSendCardLink}>
                    <img src={card}/>
                    <SText color={'#fff'} size={12} weight={500}>{'Отпр. ссылку на привязку карты'}</SText>
                </button>
                <button type={"button"} onClick={()=>push(['test', [order.id, order.users_id, order.shopId]])}>
                    <img src={cart}/>
                    <SText color={'#fff'} size={12} weight={500}>{'Доп. продажа'}</SText>
                </button>
            </div>}
            <div className={'flex flex-col gap-2'}>
                <DataRow label={'Источник заказа'} value={getOrderSource(order)}/>
                <DataRow label={'Сумма заказа'} value={getOrderSum(order, true)}/>
                <DataRow label={'Оплачено бонусами'} value={roundPrice(order.amount_bonuses ?? 0, true)}/>
            </div>
            <div className={'flex flex-row gap-12 mb-4'}>
                <div className={'flex flex-1 flex-col gap-4'}>
                    <div className={'flex flex-col mt-3 gap-2'}>
                        <SText>{'Время доставки:'}</SText>
                        <div className={'flex gap-3'}>
                            <ConnectedSelect
                                name={'date_interval'}
                                options={dateTimeOptions.dateOptions}
                                disabled={!inputs}
                                control={control}
                            />
                            <ConnectedSelect
                                disabled={!inputs}
                                name={'time_interval'}
                                options={dateTimeOptions.timeOptions}
                                control={control}
                            />
                        </div>
                    </div>
                    <div className={'flex gap-3 items-center'}>
                        <SText>{'Способ оплаты:'}</SText>
                        <ConnectedSelect
                            name={'type_payment'}
                            disabled={!inputs}
                            options={paymentOptions}
                            control={control}
                        />
                    </div>
                    <div className={'flex gap-3 items-center'}>
                        <SText>{'Стоимость доставки:'}</SText>
                        <ConnectedInput
                            rules={{required: true}}
                            name={'delivery_price'}
                            type={'number'}
                            disabled={!inputs}
                            adornment={'₽'}
                            control={control}
                        />
                    </div>
                </div>
                <div className={'flex flex-1 flex-col gap-4 mt-3 max-w-md'}>
                    <SText>{'Адрес доставки:'}</SText>
                    <div className={'flex gap-2 w-full'}><ConnectedInput
                        name={'address'}
                        disabled={!inputs}
                        control={control}
                        InputProps={{readOnly: true}}
                        className={'flex-1'}
                        rules={{required: true}}
                        label={'Адрес'}
                    />
                        <Button variant={'outlined'} disabled={!inputs} onClick={changeLocation}>
                            <EditLocationAlt/>
                        </Button>
                    </div>
                    <div className={'flex items-start gap-3'}>
                        <ConnectedInput
                            name={'flat'}
                            control={control}
                            disabled={!inputs}
                            label={'Кв/офис'}
                        />
                        <ConnectedInput
                            name={'entrance'}
                            control={control}
                            disabled={!inputs}
                            label={'Подъезд'}
                        />
                        <ConnectedInput
                            name={'floor'}
                            control={control}
                            disabled={!inputs}
                            label={'Этаж'}
                        />
                    </div>
                    <ConnectedInput
                        name={'comment'}
                        multiline
                        disabled={!inputs}
                        size={'large'}
                        control={control}
                        label={'Комментарий клиента'}
                    />
                    <Button variant={'contained'}
                            disabled={!inputs}
                            type={'submit'}
                            sx={{bgcolor: Colors.green}}
                            disableElevation>
                        {'Перевести заказ'}
                    </Button>
                </div>
            </div>
        </form>
    </PayloadMessageContainer>
}

export default OrderTransfer

const body = {
    "id": "22207",
    "users_id": "5",
    "status": "created",
    "region": "78",
    "type_payment": "1",
    "promocodes_id": "0",
    "amount": "4140",
    "amount_bonuses": "0",
    "full_amount": "4140",
    "delivery_price": "0",
    "eco_id": "911239",
    "comment": "Тест, не трогать",
    "date_interval": "2022-12-16",
    "time_interval": "18:00-19:00",
    "lat": "59.89134",
    "lon": "30.332792",
    "date": "2022-12-12 12:38:19",
    "date_update": null,
    "address": "Санкт-Петербург, Цветочная улица, 6Я",
    "status_payment": "0",
    "courier_phone": null,
    "courier": null,
    "source": "0",
    "eatsId": null,
    "deliveryId": null,
    "broniboyId": null,
    "multibonusId": null,
    "onlunchId": null,
    "sbermarketId": null,
    "orderPayments": [],
    "orderProducts": [
        {
            "id": "237829",
            "order_id": "22207",
            "product_id": "3630",
            "price": "1380",
            "quantity": "3.00",
            "status": "change",
            "date": "2022-12-12 12:38:20",
            "peramount": "1",
            "measure_unit": "шт",
            "discount_price": null,
            "start_quantity": "3.00",
            "title": "Корзина плетеная Ива бежевая/коричневая Большая",
            "image": "https://api.seasonmarket.ru/images/products/3630_product/3630_white_medium_1653559676.jpg",
            "barcode": "2009141440018",
            "fullUrl": "https://api.seasonmarket.ru/product/korzina_pletenaya_iva_bezhevayakorichnevaya_bolshaya",
            "eco_id": "9136"
        }
    ],
    "messageType": "order"
}
