import whatsApp from "../../../../assets/images/attachment/whatsapp.svg";
import React from "react";
import { PayloadMessageContainer } from "../Message";

const WhatsAppRequest = ({ message }) => {
    return <PayloadMessageContainer
        message={ message }
        meta={ { icon: whatsApp, title: 'Клиент просит связаться с ним по WhatsApp' } }
    />
}

export default WhatsAppRequest
