import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {addUserBonuses, removeUserBonuses} from "../../../redux/reducers/chatInfo";
import {Box, Modal} from "@mui/material";
import styles from "../Chat.module.css";
import SText from "../../utils/SText";
import Colors from "../../utils/Colors";
import closeModal from "../../../assets/images/closeModal.svg";

const EcoPointsModal = ({onClose}) => {
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.chatInfo.userInfo)
    const [sum, setSum] = useState(null)
    const [reason, setReason] = useState('')

    const addBonuses = () => {
        dispatch(addUserBonuses({amount: sum, reason}))
        setTimeout(onClose, 300)
    }

    const removeBonuses = () => {
        dispatch(removeUserBonuses({amount: sum, reason}))
        setTimeout(onClose, 300)
    }

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
        <Box style={{position: 'relative'}}>
            <div className={styles.ecoModalWrapper}>
                <div style={{marginBottom: 6}}><SText size={14} color={Colors.darkGray}
                                                      weight={500}>{'У клиента'}</SText></div>
                <div style={{marginBottom: 16}}><SText size={16} color={Colors.darkGray}
                                                       weight={700}>{`${userInfo.activeScores} экобаллов`}</SText></div>
                <input className={styles.pointsInput} value={sum} onChange={(e) => setSum(e.target.value)}
                       placeholder={'Укажите сумму баллов'} type="number"/>
                <textarea className={styles.reasonInput} value={reason} onChange={(e) => setReason(e.target.value)}
                          placeholder={'Укажите причину'}/>
                <div onClick={addBonuses} className={styles.greenBtn}><SText size={14} weight={700}
                                                                             color={'#fefefe'}>{'+ Начислить экобаллы'}</SText>
                </div>
                <div onClick={removeBonuses} className={styles.redBtn}><SText size={14} weight={700}
                                                                              color={'#fefefe'}>{'- Списать экобаллы'}</SText>
                </div>
            </div>
            <div onClick={onClose} className={styles.closeModal}><img src={closeModal}/></div>
        </Box>
    </Modal>
}

export default EcoPointsModal
