import React from "react";
import {PayloadMessageContainer} from "../Message";
import bell from '../../../../assets/images/attachment/bell.svg'
import {DataRow} from "./Order";
import ImagePreview from "../../../ImagePreview";
import {useSelector} from "react-redux";
import SText from "../../../utils/SText";
import {ECO_BASE_DOMAIN} from "../../../../api";
import {Divider} from "@mui/material";

//export const west = 'https://emoji.discadia.com/emojis/52a15a60-907c-4d7f-a2d7-76b49dbf530b.PNG'
export const west = 'https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg'


const Product = ({id, complaint, item}) => {
    const product = useSelector(state=>state.catalogues?.[2]?.products?.[id])

    return <div className={'flex gap-3 items-center bg-gray-50 rounded-md p-2'}>
        <ImagePreview src={product ? ECO_BASE_DOMAIN+product?.thumb : west} className={"h-12 w-12 rounded bg-gray-50"}/>
        <a href={'https://ecomarket.ru/?product=' + id}
           target={'_blank'}
           className={'flex-1'}
        >
            <SText className={'underline'}>{product?.title || 'Посмотреть продукт...'}</SText>
        </a>
        <SText weight={500}>{complaint?.toString()}</SText>
    </div>
}
const ComplaintReport = ({message}) => {

    return <PayloadMessageContainer message={message} meta={{title: 'Жалоба', icon: bell}}>
        <div className={'flex flex-col gap-2'}>
            <DataRow label={'Заказ'} value={message.order_id}/>
            <DataRow label={'Опоздание'} value={(message.late || 0) + ' мин'}/>
            <DataRow label={'Комментарий'} value={message.text}/>
        </div>
        {!!message.products?.length && <div className={'flex flex-col gap-2 py-2'}>
            <Divider/>
            <SText size={13} weight={500}>{'Продукты'}</SText>
            {message.products.map(item => {
                const [id, complaint] = Object.entries(item)[0]

                return <Product key={id} id={id} complaint={complaint}/>
            })}
        </div>}
        {!!message.courier?.length && <div className={'flex flex-col gap-2 py-2'}>
            <Divider/>
            <SText size={13} weight={500}>{'Курьер'}</SText>
            <div className={'flex flex-wrap gap-2'}>
                {message.courier.map((reason) => (
                    <SText key={reason} div className={'bg-gray-50 rounded-md p-2'}>{reason}</SText>
                ))}
            </div>
        </div>}
        {!!message.messagesImages?.length && <div className={'flex flex-col gap-2 py-2'}>
            <Divider/>
            <SText size={13} weight={500}>{'Вложения'}</SText>
            <div className={'flex flex-wrap gap-2'}>
                {message.messagesImages.map((src) => (
                    <ImagePreview src={src} key={src} className={'w-16 h-16 rounded object-cover'}/>
                ))}
            </div>
        </div>}
    </PayloadMessageContainer>
}

export default ComplaintReport
