import React, {useEffect} from "react";
import styles from './ChangeManager.module.css'
import SText from "../../utils/SText";
import {useDispatch, useSelector} from "react-redux";
import {setNewManager, transferChat} from "../../../redux/reducers/chatInfo";
import userZag from '../../../assets/images/leftBar/lk.svg'

const ChangeManager = ({onClose}) => {
    const dispatch = useDispatch()
    const managers = useSelector(state => state.chatInfo.managers)

    const onManagerClick = (id) => {
        dispatch(setNewManager(id))
        setTimeout(()=>{
            onClose()
        }, 200)
    }

    useEffect(() => {
        dispatch(transferChat())
    }, [])

    return <div className={styles.modal}>
        <div style={{marginBottom: 20}}><SText size={20} weight={700} lineHeight={20}>{'Выберите менеджера'}</SText></div>
        <div>
            {
                managers.map(item => <div onClick={() => onManagerClick(item.user.id)} className={styles.user}>
                    <div className={styles.image}>
                        {item.photo ? <img src={item.photo}/> : <img style={{height: 20, objectFit: 'none'}} src={userZag}/>}
                    </div>
                    <div>
                        <SText size={14} weight={500}>{item.user.name + ' ' + item.user.surname}</SText>
                    </div>
                </div>)
            }
        </div>
    </div>
}

export default ChangeManager