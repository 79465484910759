import email from "../../../../assets/images/attachment/email.svg";
import SText from "../../../utils/SText";
import React from "react";
import {PayloadMessageContainer} from "../Message";
import ImagePreview from "../../../ImagePreview";

const Email = ({message}) => {

    return <PayloadMessageContainer message={message}
                                    meta={{icon: email, title: 'Email'}}
    >
        <div className={'flex flex-col gap-2'}>
            <SText size={12} weight={500}>{message.subject}</SText>
            <SText size={12} weight={400}>{message.text}</SText>
            <div className={'flex gap-3 flex-wrap'}>
                {message.messagesImages?.map?.(src=>{
                    return <ImagePreview src={src} className={'w-20 h-20 object-cover rounded'}/>
                })}
            </div>
        </div>
    </PayloadMessageContainer>
}


export default Email
