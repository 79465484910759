import React from "react";
import {Box, Modal as MuiModal} from '@mui/material'
import styles from './modal.module.css'
import closeSVG from '../../assets/images/closeModal.svg'

const Modal = ({
                   onClose = () => {
                   }, children, ...props
               }) => {

    return <MuiModal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
        <Box className={styles.container}>
            <img onClick={onClose} className={styles.closeModal} src={closeSVG}/>
            {children}
        </Box>
    </MuiModal>
}

export default Modal
