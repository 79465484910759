import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Map, Placemark, YMaps} from "react-yandex-maps";
import {Autocomplete, Button, CircularProgress, debounce, TextField} from "@mui/material";

export function getApikey() {
    let items = [
        '24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
        '486bc32f-60f4-4320-9d61-c8d14935e017',
        'c0e4e3d2-913b-4873-a81a-59ea899446f8',
        '64f61949-4219-4c76-9c63-a76af46cb444',
        'a45fc414-5133-407b-a440-91d028095f30',
        'ddb478df-6120-46a8-8331-ea67cefec68c'
    ];

    return items[Math.floor(Math.random() * items.length)];
}

const loadConfig = 'Map,Placemark'

/**
 *  Чтоб запустить:
 *  1) window.onLocationSubmit = callback("Address", [lat, lon]) // подготовка сабмитера
 *  2) query push([pl, "lat,lon"]) // открытие модалки
 */

const LocationPicker = ({param, onClose}) => {
    const initialCoords = decodeURI(param).split(',')
    const mapRef = useRef(null)
    const $map = useRef(null)

    const [apikey, _] = useState(getApikey())
    const [pinLocation, setPinLocation] = useState({coords: initialCoords, address: null})
    const [query, setQuery] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [loading, setLoading] = useState(false)

    async function _getSuggestions (query) {
        if (!$map.current) return

        await $map.current.load(async ()=>{
            const suggestions = await $map.current.suggest(query)
            setSuggestions(suggestions.map(item=>{
                return {
                    name: item.displayName,
                    fullName: item.value
                }
            }))
            setLoading(false)
        })
    }

    async function onSuggestionSelect (value) {
        if (!$map.current) return
        setLoading(true)

        await $map.current.load(async ()=>{
            const res = await $map.current.geocode(value)
            let obj = res.geoObjects.get(0);
            let coordinates = obj.geometry._coordinates;
            let address = obj.properties.get('metaDataProperty.GeocoderMetaData.text');
            setPinLocation({coords: coordinates, address})
            mapRef.current.setCenter(coordinates)
            setLoading(false)
        })
    }

    const getSuggestions = useCallback(debounce(_getSuggestions, 1000), [$map.current])

    useEffect(()=>{
        if (query && query.trim?.().length) {
            setLoading(true)
            getSuggestions(query)
        }
    }, [query])

    async function submitAddress () {
        if (!window.onLocationSubmit) {
            alert('Произошла ошибка, откройте карту заново')
            return
        }

        window.onLocationSubmit(pinLocation.address, pinLocation.coords)
        onClose()
    }

    return <div className={'flex flex-1'} style={{minHeight: 400}}>
        <div className={'p-5 w-96 flex flex-col justify-between'}>
            <Autocomplete
                inputValue={query}
                onInputChange={(event, newInputValue) => {
                    setQuery(newInputValue);
                }}
                getOptionLabel={option=>option.name}
                filterOptions={x=>x}
                autoComplete
                isOptionEqualToValue={(option, value) => option.name === value.name}
                noOptionsText={'Начните вводить адрес'}
                onChange={(event, newValue) => {
                    onSuggestionSelect(newValue.fullName)
                    setQuery(newValue.name);
                }}
                options={suggestions}
                renderInput={(params) => (
                    <TextField {...params}
                               InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                       <React.Fragment>
                                           {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                           {params.InputProps.endAdornment}
                                       </React.Fragment>
                                   ),
                               }}
                               label="Поиск адреса"
                    />)}
            />

            <Button
                disabled={!pinLocation.address}
                variant={'contained'}
                onClick={submitAddress}
            >
                {'Изменить адрес'}
            </Button>
        </div>
        <YMaps query={{apikey, load: loadConfig}}>
        <Map instanceRef={mapRef}
             className={'flex-1 w-96'}
             defaultState={{center: initialCoords, zoom: 16}}
             onLoad={ymaps => $map.current = ymaps}
        >
            <Placemark geometry={{type: 'Point', coordinates: pinLocation.coords}}/>
        </Map>
        </YMaps>
    </div>
}

export default LocationPicker
