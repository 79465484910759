import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Modal from "./Modal";
import AdditionalSale from "../AdditionalSale";
import LocationPicker from "../LocationPicker";
import ReplaceCustom from "../ReplaceCustom";
import Complaint from "../Chat/components/Complaint";
import OrderCancel from "../Chat/components/OrderCancel";
import CourierMap from "../CourierMap";
import ChangeManager from "../Chat/components/ChangeManager";

export function useQueryParams() {
    const [searchParams, setSearchParams] = useSearchParams()
    const { search: paramString } = useLocation()

    const params = paramString
        .substring(1)
        .split('&')
        .map(pairString => pairString.split('='))

    function push(param = []) {
        const newParams = [
            ...params.filter(p => p[0] !== param[0]),
            param
        ].map(p => p.join('=')).join('&')
        setSearchParams('?' + newParams)
    }

    function remove(paramName) {
        const newParams = params.filter(pair => {
            return pair[0] !== paramName
        }).map(pair => pair.join('=')).join('&')
        setSearchParams('?' + newParams)
    }

    function get(paramName) {
        return searchParams.get(paramName)
    }

    return { push, remove, get, params }
}

const ModalController = ({ paramName, Component, ...props }) => {
    const { params, remove, get } = useQueryParams()

    const paramIndex = params.findIndex(pair => pair[0] === paramName)
    const render = paramIndex !== -1
    const onTop = paramIndex === params.length - 1

    function onClose() {
        remove(paramName)
    }

    if ((!Component) || !render) return null

    const paramValue = get(paramName)

    return <div style={ { zIndex: !onTop ? -1 : undefined } }>
        <Modal onClose={ onClose }>
            <Component { ...props } param={ paramValue } onClose={ onClose } />
        </Modal>
    </div>
}

const ModalSpectator = () => {

    return <>
        <ModalController Component={ AdditionalSale } paramName={ 'test' } />
        <ModalController Component={ LocationPicker } paramName={ 'pl' } />
        <ModalController Component={ CourierMap } paramName={ 'wc' } />
        <ModalController Component={ Complaint } paramName={ 'cl' } />
        <ModalController Component={ ChangeManager } paramName={ 'change' } />
        <ModalController Component={ OrderCancel } paramName={ 'co' } />
        <ModalController Component={ ReplaceCustom } paramName={ 're' } />
    </>

}

export default ModalSpectator
