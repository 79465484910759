import React, { useMemo } from 'react';
import styles from './message.module.css'
import cn from "classnames";
import SText from "../../utils/SText";
import moment from "moment";

// header icons
import bell from '../../../assets/images/attachment/bell.svg'
import TextMessage, { getExtraCN } from "./MessageTypes/TextMessage";
import TicketClose from "./MessageTypes/TicketClose";
import CallRequest from "./MessageTypes/CallRequest";
import MissedCall from "./MessageTypes/MissedCall";
import WhatsAppRequest from "./MessageTypes/WhatsAppRequest";
import Email from "./MessageTypes/Email";
import MessageSMS from "./MessageTypes/MessageSMS";
import CallRecord from "./MessageTypes/CallRecord";
import TicketAccept from "./MessageTypes/TicketAccept";
import Agreement from "./MessageTypes/Agreement";
import Order from "./MessageTypes/Order";
import OrderTransfer from "./MessageTypes/OrderTransfer";
import MessagePush from "./MessageTypes/MessagePush";
import CallResults from "./MessageTypes/CallResults";
import ImagePreview from "../../ImagePreview";
import ComplaintReport, { west } from "./MessageTypes/ComplaintReport";
import { onImgError } from "../../utils/other";

const DT_FORMAT = 'DD.MM.YY HH:mm:ss'


/*
    1 - Telegram - мессадж в tg
    2 - WhatsApp - мессадж в wa
    3 - SMS - смс
    4 - Email - мы отправили письмо
    14 - Email - письмо
    5 - App - сообщение в приложухе
    6 - Push - пуш
    7 - Complaint - жалоба
    8 - Call Request - запрос звонка
    9 - CRM Request - обращение/запрос на создание тикета
    10 - ATS Call - звонок из АТС
    11 - Call Center call - звонок с call-центра
    12 - Order - заказ

    20 - Missed call - пропущенный звонок
*/


const Message = ({ message }) => {
    const { type } = message

    const content = useMemo(() => {
        switch ('' + type) {
            case '1': case '2': case '5': case '3': case '13':
                return <TextMessage message={ message } />
            case '101':
                return <TicketClose message={ message } />
            case '8':
                return <CallRequest message={ message } />
            case '8':
                return <WhatsAppRequest message={ message } />
            case '4': case '14':
                return <Email message={ message } />
            case '10':
                return <CallRecord message={ message } />
            case '20':
                return <MissedCall message={ message } />
            case '100':
                return <TicketAccept message={ message } />
            case '99':
                return <Agreement message={ message } />
            case '12':
                return <Order message={ message } />
            case '6':
                return <MessagePush message={ message } />

            case '7':
                return <ComplaintReport message={ message } />
            case '9':
                return <PayloadMessageContainer
                    message={ message }
                    meta={ { icon: bell, title: 'Обращение' } }>
                    <SText>{ message.text }</SText>
                </PayloadMessageContainer>
            case '11': return <CallResults
                message={ message }
            />
            default:
                return <PayloadMessageContainer
                    message={ message }
                    meta={ { icon: bell, title: type } }>
                    <SText>{ message.text }</SText>
                </PayloadMessageContainer>
        }

    }, [type, message.id, message?.orderData])

    const extraCN = getExtraCN(message.from || message.direction, type)

    return <div className={ cn(styles.messageContainer, extraCN.layout) }>
        { content }
        <img onError={ onImgError }
            src={ message.dispatcherInfo?.photo }
            className={ cn(message.from !== 1 && 'hidden', 'w-12 h-12 bg-gray-300 rounded-full object-cover') }
        />
    </div>
}

export function getMessageSource(type) {
    switch ('' + type) {
        case '5': return 'Моб. прил.'
        case '1': return 'Telegram'
        case '2': return 'WhatsApp'
        case '4': return 'Email'
        case '3': return 'SMS'
        case '13': return 'Чат с курьером'
        default: return ''
    }
}

export const MessageMeta = ({ date, dispatcherInfo }) => {

    return <div className={ styles.msgMeta }>
        { !!dispatcherInfo && <SText size={ 10 } weight={ 500 } color={ '#919191' }>
            © { dispatcherInfo.name }
        </SText> }
        <SText size={ 10 } weight={ 500 } color={ '#919191' }>
            { moment(date).format(DT_FORMAT) }
        </SText>
    </div>
}


export const PayloadMessageContainer = ({ message, meta, date, children, HeaderControls = null, thin = false, large = false }) => {
    return <div className={ cn(styles.payloadMessageContainer, large && styles.large) }>
        <div className={ styles.payloadMessageHeader }>
            <img src={ meta.icon } />
            <SText size={ 14 } weight={ thin ? 400 : 500 }>{ meta.title }</SText>
            { !!HeaderControls && <HeaderControls /> }
        </div>
        { !!children && <div className={ styles.payloadMessageBody }>
            { children }
        </div> }
        <MessageMeta date={ date || message?.date } dispatcherInfo={ message?.dispatcherInfo } />
    </div>
}

export default Message
