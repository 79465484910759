import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../api";
import { toast } from "react-toastify";
import moment from "moment";

const initialState = {
    messages: [],
    dispatcherInfo: null,
    userInfo: {},
    bounds: { last: 1, first: 1 },
    pending: false,
    ticketId: null,
    watchOrders: [],
    ticket: {},
    status: null,
    templates: [],
    loadMoreAvailable: false,
    managers: [],
}


const LIMIT = 15

const reduceMessages = (affectsPagination = false) => (state, action) => {
    const messages = Object.values(action.payload.messages)

    if (affectsPagination)
        state.loadMoreAvailable = messages.length === LIMIT

    const messagesDict = messages.reduce((dict, message) => {
        return { ...dict, [message.id]: message }
    }, state.messages)

    const keys = Object.keys(messagesDict)

    state.bounds = { first: keys[0], last: keys[keys.length - 1] }
    state.watchOrders = findOrders(messagesDict)
    state.messages = messagesDict
}

export function warn(response) {
    const data = response.data

    if (!data) return

    if (data.result === 'error')
        toast.error(data.message)
}

export function success(response, message) {
    const data = response.data
    if (!data) return

    if (data.result === 'ok')
        toast.success(message)
}

export const getMessages = createAsyncThunk(
    'chat/getMessages',
    async ({ id, initial = false }, { dispatch }) => {
        if (initial)
            dispatch(setPending())
        const response = await instance.get(`tickets/${id}/messages?limit=${LIMIT}&offset=0`)
        return {
            data: response?.data?.data,
            id,
            initial
        }
    }
)

export const updateOrders = createAsyncThunk(
    'chat/refetchOrders',
    async ({ force = false }, { getState }) => {
        const { ticketId, watchOrders: messageIds } = getState().chatInfo

        if (!messageIds.length || window.nextPing && moment(window.nextPing).isAfter(moment()))
            if (!force)
                return { messages: {} }

        window.nextPing = moment().add(60, 'seconds').format()

        const response = await instance.post(`tickets/${ticketId}/message`, { messageIds }).then(r => r.data.data)
        return response
    }
)

export const getLatestMessages = createAsyncThunk(
    'chat/getLatestMessages',
    async ({ id }, { getState, dispatch }) => {
        const { last } = getState().chatInfo.bounds
        const response = await instance.get(`tickets/${id}/latest-messages?messageId=${last}`).then(r => r.data.data)
        dispatch(updateOrders({}))
        return response
    }
)

export const getPreviousMessages = createAsyncThunk(
    'chat/getPreviousMessages',
    async ({ id }, { getState }) => {
        const { first } = getState().chatInfo.bounds
        const response = await instance.get(`tickets/${id}/previous-messages?messageId=${first}&limit=${LIMIT}`).then(r => r.data.data)
        return response
    }
)

export const acceptTicket = createAsyncThunk(
    'chat/acceptTicket',
    async (_, { getState, dispatch }) => {
        const id = getState().chatInfo.ticketId

        const response = await instance.get(`tickets/${id}/accept`)
        warn(response)
        if (response?.status === 200) {
            dispatch(getMessages({ id }))
        }
    }
)

export const closeTicket = createAsyncThunk(
    'chat/acceptTicket',
    async (_, { getState, dispatch }) => {
        const id = getState().chatInfo.ticketId
        const response = await instance.get(`tickets/${id}/close`)
        warn(response)
        if (response?.status === 200) {
            dispatch(getMessages({ id }))
        }
    }
)

export const cancelTicket = createAsyncThunk(
    'chat/acceptTicket',
    async (_, { getState, dispatch }) => {
        const id = getState().chatInfo.ticketId
        const response = await instance.get(`tickets/${id}/cancel`)
        warn(response)
        if (response?.status === 200) {
            dispatch(getMessages({ id }))
        }
    }
)

export const addUserBonuses = createAsyncThunk(
    'chat/addUserBonuses',
    async ({ amount, reason }, { getState, dispatch }) => {
        const ticketId = getState().chatInfo.ticketId
        const response = await instance.post(`tickets/add-bonuses`, {
            id: ticketId,
            amount,
            reason
        })
        warn(response)
        success(response, 'Бонусы начислены')

        dispatch(getMessages({ id: ticketId }))
    }
)

export const removeUserBonuses = createAsyncThunk(
    'chat/addUserBonuses',
    async ({ amount, reason }, { getState, dispatch }) => {
        const ticketId = getState().chatInfo.ticketId
        const response = await instance.post(`tickets/remove-bonuses`, {
            id: ticketId,
            amount,
            reason
        })
        warn(response)
        success(response, 'Бонусы списаны')
        dispatch(getMessages({ id: ticketId }))
    }
)

export const sendPayLink = createAsyncThunk(
    'chat/sendPayLink',
    async ({ orderId, shopId }) => {
        const r = await instance.post('order/send-pay-link', { orderId, shopId })
        warn(r)
        success(r, 'Ссылка отправлена')
    }
)
export const sendCardLink = createAsyncThunk(
    'chat/sendCardLink',
    async ({ orderId, shopId }) => {
        const r = await instance.post('client/send-card-to-connect', { orderId, shopId }).catch(() => {
            toast.error('Ошибка сервера')
        })
        warn(r)
        success(r, 'Ссылка отправлена')
    }
)

export const sendCourierMessage = createAsyncThunk(
    'chat/sendCourierMessage',
    async ({ orderId, shopId, text }) => {
        const response = await instance.post('order/send-courrier-message', { orderId, text, shopId })
        success(response, 'Сообщение отправлено')
    }
)

export const sendReplace = createAsyncThunk(
    'chat/sendReplacement',
    async ({ replaceAction, messageId, shopId }, { dispatch }) => {
        const response = await instance.post('order/send-agreement-replacement', { replaceAction, messageId, shopId })
        success(response, 'Согласование закрыто')
        dispatch(updateOrders({ force: true }))
    }
)

export const callCourier = createAsyncThunk(
    'chat/callCourier',
    async ({ orderId, innerPhone, shopId }) => {
        const response = await instance.post('order/call-courrier', { orderId, innerPhone, shopId })
        success(response, 'Звонок инициирован')
        warn(response)
    }
)

export const getCourierLocation = createAsyncThunk(
    'chat/getCourierLocation',
    async ({ orderId }) => {
        const response = await instance.post('order/get-courrier-location', orderId)

    }
)

export const getTemplates = createAsyncThunk(
    'chat/getTemplates',
    async () => {
        const response = await instance.get('user/templates')
        return response?.data?.data?.templates
    }
)

export const postTemplate = createAsyncThunk(
    'chat/postTemplates',
    async (text, { dispatch }) => {
        const response = await instance.post('user/templates', { text })
        if (response?.data?.result === 'ok') {
            dispatch(getTemplates())
        }
    }
)

export const deleteTemplate = createAsyncThunk(
    'chat/deleteTemplate',
    async (id, { dispatch }) => {
        const response = await instance.delete('user/templates/' + id)
        if (response?.data?.result === 'ok') {
            dispatch(getTemplates())
        }
    }
)

export const updateTemplate = createAsyncThunk(
    'chat/updateTemplate',
    async ({ text, id }, { dispatch }) => {
        const response = await instance.put('user/templates/' + id, { text })
        if (response?.data?.result === 'ok') {
            dispatch(getTemplates())
        }
    }
)

export const sendMessage = createAsyncThunk(
    'chat/sendMessages',
    async ({ type, message, heading, images, id }, { dispatch }) => {
        const response = await instance.post(`tickets/${id}/send`,
            { type, message, images, heading, text: message }
        )
        if (response?.data?.result === 'ok') {
            dispatch(getLatestMessages({ id }))
        }
    }
)

export const setUserRate = createAsyncThunk(
    'chat/setUserRate',
    async (stars, { getState }) => {
        await instance.post('tickets/setUserRate', { stars, id: getState().chatInfo.ticketId })
        toast.success('Оценка сохранена')
    }
)

export const ATCcall = createAsyncThunk(
    'chat/ATCcall',
    async (_, { getState }) => {
        const chat = getState().chatInfo
        const payload = {
            shopId: chat.ticket.shop_id,
            innerPhone: JSON.parse(sessionStorage.getItem('atc')),
            phone: chat.userInfo.phone
        }
        await instance.post('client/call-client', { payload })
    }
)

export const transferOrder = createAsyncThunk(
    'chat/transferOrder',
    async (order, { dispatch }) => {
        const payload = {
            "shopId": order.shopId,
            "orderId": order.id,
            "dateInterval": order.date_interval,
            "timeInterval": order.time_interval,
            "typePayment": order.type_payment,
            "deliveryPrice": order.delivery_price,
            "address": order.address,
            "flat": order.flat ?? false,
            "entrance": order.entrance ?? false,
            "floor": order.floor ?? false,
            "lat": order.lat,
            "lon": order.lon,
            "mkad": 0,
            "comment": order.comment
        }

        const response = await instance.post('order/transfer', payload).then(r => r.data)
        dispatch(updateOrders({ force: true }))
        response.result === 'ok' ?
            toast.success(response.message) :
            toast.warn(response?.message)
    }
)

export const transferChat = createAsyncThunk(
    'chat/transferChat',
    async () => {
        const response = await instance.get('user/list')
        return response.data.data
    }
)

export const setNewManager = createAsyncThunk(
    'chat/setNewManager',
    async (newDispatcherId, { getState, dispatch }) => {
        const response = await instance.post(`tickets/${getState().chatInfo.ticketId}/pass`, { newDispatcherId })
        if (response?.status === 200) {
            dispatch(getMessages({ id: getState().chatInfo.ticketId }))
        }
        response.result === 'ok' ?
            toast.success(response.message) :
            toast.warn(response?.message)
    }
)

const chatInfoSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setPending: (state, action) => {
            state.pending = true
        }
    },
    extraReducers: {
        [getLatestMessages.fulfilled]: reduceMessages(false),
        [updateOrders.fulfilled]: reduceMessages(false),
        [getPreviousMessages.fulfilled]: reduceMessages(true),
        [transferChat.fulfilled]: (state, action) => {
            state.managers = action.payload
        },
        [getMessages.fulfilled]: (state, action) => {
            const messages = action.payload.data.messages

            state.loadMoreAvailable = messages.length === LIMIT

            const messagesDict = messages.reduce((dict, message) => {
                return { ...dict, [message.id]: message }
            }, action.payload.initial ? {} : state.messages)

            const keys = Object.keys(messagesDict)

            state.dispatcherInfo = action.payload.data.dispatcherInfo
            state.bounds = { first: keys[0], last: keys[keys.length - 1] }
            state.watchOrders = findOrders(messagesDict)
            state.messages = messagesDict
            state.userInfo = action.payload.data.userInfo
            state.status = action.payload.data.ticket?.status
            state.ticket = action.payload.data.ticket
            state.ticketId = action.payload.id
            state.pending = false
        },
        [getTemplates.fulfilled]: (state, action) => {
            state.templates = action.payload
        }
    }
})


function findOrders(messagesDict) {
    let orders = []

    for (let id in messagesDict) {
        if (messagesDict[id].type === '12' || messagesDict[id].type === '99')
            orders.push(id)
    }

    return orders
}


export function selectMessages(state) {
    return Object.values(state.chatInfo.messages).reverse()
}

export const { setPending } = chatInfoSlice.actions

export default chatInfoSlice.reducer
