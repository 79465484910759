import {Map, Placemark, YMaps} from "react-yandex-maps";
import React, {useEffect, useRef, useState} from "react";
import {getApikey} from "../LocationPicker";
import {instance} from "../../api";
import PreloaderModal from "../common/PreloaderModal";
import {toast} from "react-toastify";

const loadConfig = 'Map,Placemark'

const courier = 'https://ecomarket.ru/static/media/courierOnMap.1d43c59eeaea045d5a5a39254465489b.svg'
const finish = 'https://ecomarket.ru/static/media/courierFinish.966dbe6c973153e23828bdbd8146dd68.svg'

const CourierMap = ({param, onClose}) => {
    const [orderId, shopId] = param.split(',')
    const [apikey, _] = useState(getApikey())
    const mapRef = useRef(null)
    const $map = useRef(null)
    const [point, setPoint] = useState(false)
    const [orderPoint, setOrderPoint] = useState(false)

    async function getCourierLocation () {
        const _response = await instance.post('order/get-courrier-location', {orderId, shopId}).then(r=>r.data)
        const response = _response.data
        if (response?.lat) {
            setPoint([response.lat, response.lon])
            setOrderPoint([response.order_lat, response.order_lon])
        } else {
            toast.warn(_response.message)
            setTimeout(onClose, 1000)
        }
    }

    useEffect(()=>{
        getCourierLocation()
    }, [])


    if (!point || !orderPoint) return <PreloaderModal/>

    return <div className={'flex flex-1'} style={{minHeight: 400, minWidth: 600}}>
        <YMaps query={{apikey, load: loadConfig}}>
        <Map instanceRef={mapRef}
             className={'flex-1 w-96'}
             defaultState={{center: point, zoom: 12}}
             onLoad={ymaps => $map.current = ymaps}
        >
            <Placemark geometry={{type: 'Point', coordinates: point}}
                       options={{iconLayout: 'default#image', iconImageHref: courier}}/>
            <Placemark geometry={{type: 'Point', coordinates: orderPoint}}
                       options={{iconLayout: 'default#image', iconImageHref: finish}}/>
        </Map>
    </YMaps>
    </div>
}

export default CourierMap
