import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {deleteTemplate, getTemplates, postTemplate, updateTemplate} from "../../../redux/reducers/chatInfo";
import {ClickAwayListener} from "@mui/material";
import styles from "../Chat.module.css";
import SText from "../../utils/SText";
import closeModal from "../../../assets/images/closeModal.svg";

const Templates = ({onClose, setChatValue}) => {
    const dispatch = useDispatch()
    const templates = useSelector(state => state.chatInfo.templates)
    const [selected, setSelected] = useState(null)
    const [openInput, setOpenInput] = useState(false)
    const [redValue, setRedValue] = useState('')

    const postNewTemplate = () => {
        dispatch(postTemplate(redValue))
        setOpenInput(false)
        setRedValue('')
    }

    const updateOldTemplate = () => {
        if (selected) {
            dispatch(updateTemplate({id: selected?.id, text: redValue}))
            setSelected(null)
            setOpenInput(false)
            setRedValue('')
        }
    }

    const deleteOldTemplate = () => {
        if (selected) {
            dispatch(deleteTemplate(selected?.id))
            setSelected(null)
            setOpenInput(false)
            setRedValue('')
        }
    }

    useEffect(() => {
        dispatch(getTemplates())
    }, [])

    return <ClickAwayListener onClickAway={onClose}>
        <div className={styles.templates}>
            <div style={{position: 'relative'}}>
                <div className={styles.templatesBtns}>
                    <div style={{background: '#34c759'}} onClick={() => {
                        setSelected(null)
                        setOpenInput(prev => !prev)
                        setRedValue('')
                    }}
                         className={styles.templateBtn}><SText color={'#fff'} size={11}
                                                               weight={500}
                                                               lineHeight={11}>{'+ новый'}</SText>
                    </div>
                    <div style={{background: '#909090'}} onClick={() => {
                        if (selected && !openInput) {
                            setOpenInput(prev => !prev)
                            setRedValue(selected?.text)
                        } else if (selected && openInput) {
                            setOpenInput(prev => !prev)
                            setRedValue('')
                        }
                    }} className={styles.templateBtn}><SText color={'#fff'} size={11}
                                                             weight={500}
                                                             lineHeight={11}>{'редактировать'}</SText>
                    </div>
                    <div onClick={deleteOldTemplate} style={{background: '#ff5252'}} className={styles.templateBtn}>
                        <SText color={'#fff'} size={11}
                               weight={500}
                               lineHeight={11}>{'удалить'}</SText>
                    </div>
                </div>
                <div className={styles.templatesText}>
                    {
                        templates.map(item => <div onDoubleClick={() => setChatValue(selected?.text)}
                                                   style={{background: selected?.id === item.id ? '#f3f3f3' : '#fff'}}
                                                   onClick={() => setSelected(item)}
                                                   className={styles.template}>{item.text}</div>)
                    }
                </div>
                {openInput && <div className={styles.templateInput}>
                    <input autoFocus value={redValue} onKeyPress={(e => {
                        if (selected && e.key === 'Enter') updateOldTemplate()
                        else if (!selected && e.key === 'Enter') postNewTemplate()
                    })} onChange={(e) => {
                        setRedValue(e.target.value)
                    }} type="text"/>
                </div>}
            </div>
            <img onClick={onClose} className={styles.closeModal} src={closeModal}/>
        </div>
    </ClickAwayListener>
}

export default Templates
