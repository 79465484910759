import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {sendEcoRequest} from "../../api";

const emptyCatalogue = {
    products: {},
    categories: {}
}
const initialState = {
    1: emptyCatalogue,
    2: emptyCatalogue,

}

export function selectProducts (state) {
    const currentShop = state.chatInfo.ticket?.shop_id ?? 0
    if (!currentShop) return {products: {}}

    return state.catalogues[currentShop]?.products
}

export const selectProduct = (id) => (state) =>{
    return selectProducts(state)[id]
}

async function getEcoCat (id) {
    const payload = {"REGION": 77, "source": "mobile", "TEST_CASE": "A", "token": null}
    const response = await sendEcoRequest("appStartUp_v5", payload)

    return {
        products: response.products,
        categories: response.groups,
        shopId: id
    }
}

export const loadCatalogue = createAsyncThunk(
    'loadCatalogue',
    async ({shopId}) => {
        switch (shopId) {
            case 2: return await getEcoCat(2)
            default: throw 'ass'
        }
    }
)


const cataloguesSlice = createSlice({
    name: 'catalogues',
    initialState,
    extraReducers: {
        [loadCatalogue.fulfilled]: (state, action) => {
            const shopId = action.payload.shopId
            state[shopId] = {
                products: action.payload.products,
                categories: action.payload.categories
            }
        }
    }
})


export default cataloguesSlice.reducer

