import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {createHeaders, instance} from "../../api";

const initialState = {
    user: {
        id: null,
        phone: null,
        token: null,
        name: '',
        surname: '',
    },
    roles: {
        operator: null,
    },
    rating: null,
    photo: '',
    monthKPI: {
        motivation: 0,
        demotivation: 0
    },
    dayKPI: {
        motivation: 0,
        demotivation: 0
    }
}

export const getToken = createAsyncThunk(
    'user/getToken',
    async (secret, {dispatch}) => {
        if (!secret) {
            dispatch(getUser())
            return
        }

        const response = await instance.post('auth/code', {secret})
        localStorage.setItem('token', response?.data?.data?.user?.token)
        Object.assign(instance.defaults, {headers: createHeaders()})
        dispatch(getUser())
        return response?.data?.data
    }
)

export const getUser = createAsyncThunk(
    'user/getUser',
    async () => {
        const response = await instance.get('user')
        return response?.data?.data
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: {
        [getUser.fulfilled]: (state, action) => {
            return action.payload
        },
    }
})

export default userSlice.reducer
