import React from 'react';
import {PayloadMessageContainer} from "../Message";
import phone from '../../../../assets/images/attachment/phoneGray.svg'
import {DataRow} from "./Order";

const CallResults = ({message}) => {

    return <PayloadMessageContainer message={message} meta={{icon: phone, title: 'Обзвон'}}>
        <div className={'flex flex-col gap-1'}>
            {Object.entries(message.answers).map(([q, a]) => (
                <DataRow key={q} label={q} value={a}/>
            ))}
        </div>
    </PayloadMessageContainer>
}

export default CallResults
