import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './input.css'
import 'moment/locale/ru'
import moment from "moment";
moment.locale('ru')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
