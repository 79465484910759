import styles from "../message.module.css";
import SText from "../../../utils/SText";
import React, {useMemo, useState} from "react";
import Colors from "../../../utils/Colors";
import bell from "../../../../assets/images/attachment/bell.svg";
import ruble from "../../../../assets/images/attachment/rubleWhite.svg";
import card from "../../../../assets/images/attachment/card.svg";
import {PayloadMessageContainer} from "../Message";
import {
    get1CBadgeProps,
    getOrderSource,
    getPaymentBadgeProps,
    getPaymentType,
    getStatusBadgeProps, prettyDateTimeSlot,
    roundPrice, useVisibility
} from "./utils";
import OrderTransfer from "./OrderTransfer";
import {Box, Button, Divider, Modal} from "@mui/material";
import {useDispatch} from "react-redux";
import {
    callCourier,
    getCourierLocation,
    sendCardLink,
    sendCourierMessage,
    sendPayLink,
    updateOrders
} from "../../../../redux/reducers/chatInfo";
import {useQueryParams} from "../../../common/ModalSpectator";
import closeModal from "../../../../assets/images/closeModal.svg";
import {toast} from "react-toastify";
import cn from "classnames";

export const DataRow = ({label, value}) => (
    !value ? null :
        <div className={'flex gap-1 items-baseline'}>
            <SText size={14} weight={400}>{label + ': '}</SText>
            <SText size={14} weight={500}>{value}</SText>
        </div>
)

export const HeaderBadge = ({text, color = '#007AFF'}) => {
    return <div className={styles.orderHeaderBadge} style={{backgroundColor: color}}>
        <SText size={13} weight={500} color={'#fff'}>
            {text}
        </SText>
    </div>
}

export const OrderControls = ({order}) => {
    const dispatch = useDispatch()
    const {push} = useQueryParams()
    const {inputs} = useVisibility()

    function onRefresh() {
        dispatch(updateOrders({force: true}))
    }

    function onCancel() {
        push(['co', [order.shopId || 2, order.id, order.label]])
    }

    return <div className={'flex flex-1 pl-2 justify-between'}>
        {(inputs && order.status !== 'canceled') ? <Button onClick={onCancel} size={'small'} color={'warning'}>
            <SText size={10} weight={500} color={Colors.red}>{'Отменить'}</SText>
        </Button> : <div/>}
        <Button size={'small'} onClick={onRefresh}>
            <SText size={10} weight={500} color={'#00000022'}>{'Обновить'}</SText>
        </Button>
    </div>
}

const Order = ({message}) => {
    const dispatch = useDispatch()

    const order = message.orderData ?? message

    const {inputs, isSeason} = useVisibility()

    const [courierMessageText, setCourierMessageText] = useState(null)
    const [courierMessageModalVisible, setCourierMessageModalVisible] = useState(false)
    const {push} = useQueryParams()

    if (order.status === 'created') {
        return <OrderTransfer message={message}/>
    }

    const adminUrl = order.adminUrl

    function onSendPayLink() {
        dispatch(sendPayLink({orderId: order.id, shopId: order.shopId}))
    }

    function onSendCardLink() {
        dispatch(sendCardLink({orderId: order.id, shopId: order.shopId}))
    }

    function courierMap () {
        push(['wc', [order.id, order.shopId]])
        //dispatch(getCourierLocation({orderId: order.id}))
    }

    const courierCall = () => {
        if (!sessionStorage.getItem('atc')) toast.warn('Введите номер ATC')
        dispatch(callCourier({orderId: order.id, shopId: order.shopId, innerPhone: sessionStorage.getItem('atc')}))
    }

    const courierMessage = () => {
        dispatch(sendCourierMessage({orderId: order.id, shopId: order.shopId, text: courierMessageText}))
        setTimeout(() => {
            setCourierMessageModalVisible(false)
        }, 200)
    }

    return <PayloadMessageContainer
        meta={{icon: bell, title: 'Заказ'}}
        message={message}
        date={message.date}
        HeaderControls={() => <OrderControls order={order}/>}
    >
        <a href={adminUrl} target={'_blank'} className={styles.orderHeader}>
            <SText weight={500}>
                {`Заказ ${order.label || order.id} ${isSeason ? 'ID: '+order.id : ''}`}
            </SText>
            <HeaderBadge {...getStatusBadgeProps(order)}/>
            <HeaderBadge {...getPaymentBadgeProps(order)}/>
            <HeaderBadge {...get1CBadgeProps(order)}/>
        </a>
        {(!!inputs && order.status_payment == 0) && <div className={styles.orderButtons}>
            <button onClick={onSendPayLink}>
                <img src={ruble}/>
                <SText color={'#fff'} size={12} weight={500}>{'Отправить ссылку на оплату'}</SText>
            </button>
            <button onClick={onSendCardLink}>
                <img src={card}/>
                <SText color={'#fff'} size={12} weight={500}>{'Отправить ссылку на привязку карты '}</SText>
            </button>
        </div>}
        <div className={styles.orderInfo}>
            <DataRow label={'Источник заказа'} value={getOrderSource(order)}/>
            <DataRow label={'Сумма заказа'} value={roundPrice(order.amount, true)}/>
            <DataRow label={'Стоимость доставки'} value={roundPrice(order.delivery_price, true)}/>
            <DataRow label={'Способ оплаты'} value={getPaymentType(order)}/>
            <DataRow label={'Адрес доставки'} value={order.address}/>
            <DataRow label={'Квартира'} value={order.flat}/>
            <DataRow label={'Этаж'} value={order.floor}/>
            <DataRow label={'Подъезд'} value={order.entrance}/>
            <DataRow label={'Время доставки'} value={prettyDateTimeSlot(order.date_interval, order.time_interval)}/>
        </div>
        <Divider/>
        <div className={styles.orderInfo}>
            <DataRow label={'Курьер'} value={order.courier}/>
            <DataRow label={'Телефон курьера'} value={order.courier_phone}/>
        </div>
        <div className={cn(styles.orderButtons, (!order.courier_phone || !inputs) && 'hidden')}>
            <button onClick={courierMap}>
                <SText color={'#fff'} size={12} weight={500}>{'Курьер на карте'}</SText>
            </button>
            <button onClick={() => setCourierMessageModalVisible(true)}><SText color={'#fff'} size={12}
                                                                               weight={500}>{'Написать курьеру'}</SText>
            </button>
            <button onClick={courierCall}><SText color={'#fff'} size={12} weight={500}>{'Позвонить курьеру'}</SText></button>
        </div>
        {courierMessageModalVisible && <SendCourierMessage value={courierMessageText} setValue={setCourierMessageText}
                                                           onClose={() => setCourierMessageModalVisible(false)}
                                                           onConfirm={courierMessage}/>}
    </PayloadMessageContainer>
}

const SendCourierMessage = ({value, setValue, onClose, onConfirm}) => {

    return <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
    >
        <Box style={{position: 'relative'}}>
            <div className={styles.modal}>
                <div style={{marginBottom: 5}}><SText weight={500}>{'Введите сообщение'}</SText></div>
                <input autoFocus onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        onConfirm()
                    }
                }} value={value} onChange={(e) => setValue(e.target.value)} className={styles.atcInput}
                       type="text"/>
                <div onClick={onConfirm} className={styles.confirmNumberBtn}><SText weight={500}
                                                                                    color={'#fff'}>{'Отправить'}</SText>
                </div>
            </div>
            <div onClick={onClose} className={styles.closeModal}><img src={closeModal}/></div>
        </Box>
    </Modal>
}

export default Order
